// Chakra imports
import {
	Box,
	Button,
	Flex,
	Select,
	SimpleGrid,
	Icon,
	Stack,
	Tab,
	TabList,
	TabPanel,
	TabPanels,
	Tabs,
	Text,
	useColorModeValue,
} from '@chakra-ui/react';

import { useHistory } from "react-router-dom";

// Custom components
import Card from 'components/card/Card';
import InputField from 'components/fields/InputField';
import TextField from 'components/fields/TextField';
import Dropzone from './components/Dropzone';
import React, { useContext, useState } from 'react';

// Assets
import { MdOutlineCloudUpload } from 'react-icons/md';
import banner from 'assets/img/backgrounds/banner.png';
import { StaticDataContext } from 'context/static-data-context';
import { getTaxonomyTermValueById, sorters } from 'libs/arrayHelper';
import { iTaxonomyTerm } from 'types/iTaxonomyTerm';
import { iRoom } from 'types/iRoom';
import { isInt } from 'libs/typesHelper';
import { AuthContext } from 'context/auth-context';
import { createItem } from 'services/roomsService';

export default function NewRoom() {
	const { currentUser } = useContext(AuthContext);
	const { staticTaxonomyRoomStatuses, staticTaxonomyCountries, staticTaxonomyProvinces, staticTaxonomyCities } = useContext(StaticDataContext);
	const history = useHistory();

	// Chakra Color Mode
	const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
	const backgroundColor = useColorModeValue('secondaryGray.200', 'fobos.darkGrey');

	const emptyRoom: iRoom = {
		id: '',
		image: 'emptyImage',
		status_id: '-'
	};
	const [newRoom, setNewRoom]: [iRoom, (element: iRoom) => void] = useState(emptyRoom);


	const createRoom = async () => {
		const createResult = await createItem(currentUser, newRoom);
		if (createResult?.id) {
			alert("Empresa dada de alta correctamente");
			history.push('/admin/rooms/' + createResult?.id);
		} else {
			alert("Algo ha fallado, por favor, inténtalo de nuevo");
		}
	}

	const changeHandler = (event: any) => {
		let tmpRoom = newRoom;

		if (isInt(event.target.value) && !['latitude', 'longitude'].includes(event.target.id)) {
			//@ts-ignore
			tmpRoom[event.target.id] = parseInt(event.target.value);
		} else {
			//@ts-ignore
			tmpRoom[event.target.id] = event.target.value + "";
		}

		setNewRoom(tmpRoom);
	}

	const textColor = useColorModeValue('secondaryGray.900', 'white');
	const [activeBullets, setActiveBullets] = useState({
		roomDetails: true,
		logo: false,
		social: false,
		geo: false,
		resume: false
	});

	const roomDetailTab = React.useRef() as React.MutableRefObject<HTMLInputElement>;
	const logoTab = React.useRef() as React.MutableRefObject<HTMLInputElement>;
	const socialTab = React.useRef() as React.MutableRefObject<HTMLInputElement>;
	const geoTab = React.useRef() as React.MutableRefObject<HTMLInputElement>;
	const resumeTab = React.useRef() as React.MutableRefObject<HTMLInputElement>;
	const brand = useColorModeValue('brand.500', 'brand.400');

	return (
		<Flex direction='column' minH='100vh' align='center' pt={{ sm: '125px', lg: '75px' }} position='relative'>
			<Box
				h='45vh'
				bg='linear-gradient(135deg, #868CFF 0%, #4318FF 100%)'
				bgImage={banner}
				position='absolute'
				w='100%'
				borderRadius='30px'
			/>
			<Tabs
				variant='unstyled'
				mt={{ base: '60px', md: '165px' }}
				zIndex='0'
				display='flex'
				flexDirection='column'>
				<TabList display='flex' alignItems='center' alignSelf='center' justifySelf='center'>
					<Tab
						ref={roomDetailTab}
						w={{ sm: '60px', md: '125px', lg: '150px' }}
						onClick={() =>
							setActiveBullets({
								roomDetails: true,
								logo: false,
								social: false,
								geo: false,
								resume: false
							})}>
						<Flex
							direction='column'
							justify='center'
							align='center'
							position='relative'
							_before={{
								content: "''",
								width: { sm: '120px', md: '250px', lg: '150px' },
								height: '3px',
								bg: activeBullets.logo ? 'white' : '#8476FF',
								left: { sm: '10px', md: '20px' },
								top: {
									sm: activeBullets.roomDetails ? '6px' : '4px',
									md: null
								},
								position: 'absolute',
								bottom: activeBullets.roomDetails ? '40px' : '38px',

								transition: 'all .3s ease'
							}}>
							<Box
								zIndex='1'
								border='2px solid'
								borderColor={activeBullets.roomDetails ? 'white' : '#8476FF'}
								bg='linear-gradient(135deg, #868CFF 0%, #4318FF 100%)'
								w='16px'
								h='16px'
								mb='8px'
								borderRadius='50%'
							/>
							<Text
								color={activeBullets.roomDetails ? 'white' : 'gray.300'}
								fontWeight={activeBullets.roomDetails ? 'bold' : 'normal'}
								display={{ sm: 'none', md: 'block' }}>
								Datos
							</Text>
						</Flex>
					</Tab>
					<Tab
						ref={logoTab}
						w={{ sm: '60px', md: '125px', lg: '150px' }}
						onClick={() =>
							setActiveBullets({
								roomDetails: true,
								logo: true,
								social: false,
								geo: false,
								resume: false
							})}>
						<Flex
							direction='column'
							justify='center'
							align='center'
							position='relative'
							_before={{
								content: "''",
								width: { sm: '120px', md: '250px', lg: '150px' },
								height: '3px',
								bg: activeBullets.social ? 'white' : '#8476FF',
								left: { sm: '12px', md: '20px' },
								top: '6px',
								position: 'absolute',
								bottom: activeBullets.logo ? '40px' : '38px',

								transition: 'all .3s ease'
							}}>
							<Box
								zIndex='1'
								border='2px solid'
								borderColor={activeBullets.logo ? 'white' : '#8476FF'}
								bg='linear-gradient(135deg, #868CFF 0%, #4318FF 100%)'
								w='16px'
								h='16px'
								mb='8px'
								borderRadius='50%'
							/>
							<Text
								color={activeBullets.logo ? 'white' : 'gray.300'}
								fontWeight={activeBullets.logo ? 'bold' : 'normal'}
								display={{ sm: 'none', md: 'block' }}>
								Logo
							</Text>
						</Flex>
					</Tab>
					<Tab
						ref={socialTab}
						w={{ sm: '60px', md: '125px', lg: '150px' }}
						onClick={() =>
							setActiveBullets({
								roomDetails: true,
								logo: true,
								social: true,
								geo: false,
								resume: false
							})}>
						<Flex
							direction='column'
							justify='center'
							align='center'
							position='relative'
							_before={{
								content: "''",
								width: { sm: '120px', md: '250px', lg: '150px' },
								height: '3px',
								bg: activeBullets.geo ? 'white' : '#8476FF',
								left: { sm: '12px', md: '28px' },
								top: '6px',
								position: 'absolute',
								bottom: activeBullets.social ? '40px' : '38px',
								transition: 'all .3s ease'
							}}>
							<Box
								zIndex='1'
								border='2px solid'
								borderColor={activeBullets.social ? 'white' : '#8476FF'}
								bg='linear-gradient(135deg, #868CFF 0%, #4318FF 100%)'
								w='16px'
								h='16px'
								mb='8px'
								borderRadius='50%'
							/>
							<Text
								color={activeBullets.social ? 'white' : 'gray.300'}
								fontWeight={activeBullets.social ? 'bold' : 'normal'}
								display={{ sm: 'none', md: 'block' }}>
								Social
							</Text>
						</Flex>
					</Tab>
					<Tab
						ref={geoTab}
						w={{ sm: '60px', md: '125px', lg: '150px' }}
						onClick={() =>
							setActiveBullets({
								roomDetails: true,
								logo: true,
								social: true,
								geo: true,
								resume: false
							})}>
						<Flex
							direction='column'
							justify='center'
							align='center'
							position='relative'
							_before={{
								content: "''",
								width: { sm: '120px', md: '250px', lg: '150px' },
								height: '3px',
								bg: activeBullets.resume ? 'white' : '#8476FF',
								left: { sm: '12px', md: '23px' },
								top: '6px',
								position: 'absolute',
								bottom: activeBullets.geo ? '40px' : '38px',
								transition: 'all .3s ease'
							}}>
							<Box
								zIndex='1'
								border='2px solid'
								borderColor={activeBullets.geo ? 'white' : '#8476FF'}
								bg='linear-gradient(135deg, #868CFF 0%, #4318FF 100%)'
								w='16px'
								h='16px'
								mb='8px'
								borderRadius='50%'
							/>
							<Text
								color={activeBullets.geo ? 'white' : 'gray.300'}
								fontWeight={activeBullets.geo ? 'bold' : 'normal'}
								display={{ sm: 'none', md: 'block' }}>
								Geo
							</Text>
						</Flex>
					</Tab>
					<Tab
						ref={resumeTab}
						w={{ sm: '60px', md: '125px', lg: '150px' }}
						onClick={() =>
							setActiveBullets({
								roomDetails: true,
								logo: true,
								social: true,
								geo: true,
								resume: true
							})}>
						<Flex direction='column' justify='center' align='center' position='relative'>
							<Box
								zIndex='1'
								border='2px solid'
								borderColor={activeBullets.resume ? 'white' : '#8476FF'}
								bg='linear-gradient(135deg, #868CFF 0%, #4318FF 100%)'
								w='16px'
								h='16px'
								mb='8px'
								borderRadius='50%'
							/>
							<Text
								color={activeBullets.resume ? 'white' : 'gray.300'}
								fontWeight={activeBullets.resume ? 'bold' : 'normal'}
								display={{ sm: 'none', md: 'block' }}>
								Resumen
							</Text>
						</Flex>
					</Tab>
				</TabList>
				<TabPanels mt='24px' maxW={{ md: '90%', lg: '100%' }} mx='auto'>
					<TabPanel w={{ sm: '330px', md: '700px', lg: '850px' }} p='0px' mx='auto'>
						<Card p='30px'>
							<Text color={textColor} fontSize='2xl' fontWeight='700' mb='20px'>
								Información de empresa
							</Text>
							<Flex direction='column' w='100%'>
								<SimpleGrid columns={{ base: 1, md: 2 }} gap='20px'>
									<Stack direction='column' gap='20px'>
										<InputField
											mb='0px'
											id='name'
											placeholder='ej: FobosEscape'
											label='Nombre Empresa'
											defaultValue={newRoom?.name}
											onChange={(e: any) => { changeHandler(e); }}
										/>
										<InputField mb='0px' id='email' placeholder='ej: contacto@fobosescape.com ' label='Email' onChange={(e: any) => { changeHandler(e); }} />
										<InputField
											mb='0px'
											id='phone'
											placeholder='ej. 666-777-888'
											label='Teléfono'
											defaultValue={newRoom?.phone}
											onChange={(e: any) => { changeHandler(e); }}
										/>
									</Stack>
									<Stack direction='column' gap='20px'>
										<Select
											mb='0px'
											id='country'
											placeholder='Seleciona el país'
											defaultValue={newRoom?.country || ""}
											onChange={(e: any) => { changeHandler(e); }}
										>
											{staticTaxonomyCountries.sort(sorters.byName).map((element: iTaxonomyTerm) => (
												<option value={element.id}>{element.name}</option>
											))}
										</Select>
										<Select mb='0px' id='province' placeholder='Selecciona la pronvicia'
											defaultValue={newRoom?.province}
											onChange={(e: any) => { changeHandler(e); }}
										>
											{staticTaxonomyProvinces.sort(sorters.byName).map((element: iTaxonomyTerm) => (
												<option value={element.id}>{element.name}</option>
											))}
										</Select>
										<Select mb='0px' id='city' placeholder='Selecciona Ciudad'
											defaultValue={newRoom?.city}
											onChange={(e: any) => { changeHandler(e); }}
										>
											{staticTaxonomyCities.sort(sorters.byName).map((element: iTaxonomyTerm) => (
												<option value={element.id}>{element.name}</option>
											))}
										</Select>
										<InputField mb='0px' id='address' placeholder='ej: C/ Piruleta' label='Dirección' onChange={(e: any) => { changeHandler(e); }} />
									</Stack>
								</SimpleGrid>
								<SimpleGrid gap='20px'>
									<Stack direction='column' gap='20px'>

										<TextField
											h='146px'
											mb='0px'
											id='description'
											placeholder='ej: detalles de la empresa'
											label='Descripción'
											defaultValue={newRoom?.description}
											onChange={(e: any) => { changeHandler(e); }}
										/>
									</Stack>
								</SimpleGrid>
								<Flex justify='space-between' mt='24px'>
									<Button
										variant='darkBrand'
										fontSize='sm'
										borderRadius='16px'
										w={{ base: '128px', md: '148px' }}
										h='46px'
										ms='auto'
										onClick={() => logoTab.current.click()}>
										Siguiente
									</Button>
								</Flex>
							</Flex>
						</Card>
					</TabPanel>
					<TabPanel w={{ sm: '330px', md: '700px', lg: '850px' }} p='0px' mx='auto'>
						<Card p='30px'>
							<Text color={textColor} fontSize='2xl' fontWeight='700' mb='20px'>
								Logo
							</Text>
							<Dropzone
								content={
									<Box>
										<Icon as={MdOutlineCloudUpload} w='80px' h='80px' color={textColor} />
										<Text
											mx='auto'
											mb='12px'
											fontSize='lg'
											fontWeight='700'
											whiteSpace='pre-wrap'
											color={textColor}>
											Arrastra aquí el logo de la sala o {' '}
											<Text as='span' fontSize='lg' fontWeight='700' color={brand}>
												buscar
											</Text>
										</Text>
										<Text fontSize='sm' fontWeight='500' color='secondaryGray.500'>
											Los formatos soportados son PNG, JPG y GIF.
										</Text>
									</Box>
								}
							/>
							<Flex justify='space-between' mt='24px'>
								<Button
									variant='light'
									fontSize='sm'
									borderRadius='16px'
									w={{ base: '128px', md: '148px' }}
									h='46px'
									onClick={() => roomDetailTab.current.click()}>
									Anterior
								</Button>
								<Button
									variant='darkBrand'
									fontSize='sm'
									borderRadius='16px'
									w={{ base: '128px', md: '148px' }}
									h='46px'
									onClick={() => socialTab.current.click()}>
									Siguiente
								</Button>
							</Flex>
						</Card>
					</TabPanel>
					<TabPanel w={{ sm: '330px', md: '700px', lg: '850px' }} p='0px' mx='auto'>
						<Card p='30px'>
							<Text color={textColor} fontSize='2xl' fontWeight='700' mb='20px'>
								Social
							</Text>
							<Flex direction='column' w='100%'>
								<Stack direction='column' spacing='20px'>
									<SimpleGrid columns={{ base: 1, md: 2 }} gap={{ base: '0px', md: '20px' }}>
										<InputField
											id='instagram' placeholder='fobosescape' label='Instagram'
											defaultValue={newRoom?.instagram}
											onChange={(e: any) => { changeHandler(e); }}
										/>
										<InputField
											id='web' placeholder='ej: https://fobosescape.com' label='Web'
											defaultValue={newRoom?.web}
											onChange={(e: any) => { changeHandler(e); }}
										/>
									</SimpleGrid>
								</Stack>
								<Flex justify='space-between' mt='24px'>
									<Button
										variant='light'
										fontSize='sm'
										borderRadius='16px'
										w={{ base: '128px', md: '148px' }}
										h='46px'
										onClick={() => logoTab.current.click()}>
										Anterior
									</Button>
									<Button
										variant='darkBrand'
										fontSize='sm'
										borderRadius='16px'
										w={{ base: '128px', md: '148px' }}
										onClick={() => geoTab.current.click()}
										h='46px'>
										Siguiente
									</Button>
								</Flex>
							</Flex>
						</Card>
					</TabPanel>
					<TabPanel w={{ sm: '330px', md: '700px', lg: '850px' }} p='0px' mx='auto'>
						<Card p='30px'>
							<Text color={textColor} fontSize='2xl' fontWeight='700' mb='20px'>
								Geo
							</Text>
							<Flex direction='column' w='100%'>
								<Stack direction='column' spacing='20px'>
									<SimpleGrid columns={{ base: 1, md: 2 }} gap={{ base: '0px', md: '20px' }}>
										<InputField
											id='latitude' placeholder='ej: 13.1896' label='Latitud'
											defaultValue={newRoom?.latitude}
											onChange={(e: any) => { changeHandler(e); }}
										/>
										<InputField
											id='longitude' placeholder='ej: -3.8954864' label='Longitud'
											defaultValue={newRoom?.longitude}
											onChange={(e: any) => { changeHandler(e); }}
										/>
									</SimpleGrid>
								</Stack>
								<Flex justify='space-between' mt='24px'>
									<Button
										variant='light'
										fontSize='sm'
										borderRadius='16px'
										w={{ base: '128px', md: '148px' }}
										h='46px'
										onClick={() => socialTab.current.click()}>
										Anterior
									</Button>
									<Button
										variant='darkBrand'
										fontSize='sm'
										borderRadius='16px'
										w={{ base: '128px', md: '148px' }}
										onClick={() => resumeTab.current.click()}
										h='46px'>
										Siguiente
									</Button>
								</Flex>
							</Flex>
						</Card>
					</TabPanel>
					<TabPanel w={{ sm: '330px', md: '700px', lg: '850px' }} p='0px' mx='auto'>
						<Card p='30px'>
							<Text color={textColor} fontSize='2xl' fontWeight='700' mb='20px'>
								Resumen
							</Text>
							<Text color={textColor} fontSize='lg' fontWeight='700' mb='20px'>
								Información de la nueva empresa
							</Text>
							<Flex direction='column' w='100%'>
								<Stack direction='column' spacing='20px'>
									<SimpleGrid columns={{ base: 2, md: 4 }} gap={{ base: '0px', md: '20px' }}>
										<label>Nombre: </label><span><i>{newRoom?.name}</i></span>
										<label>País: </label><span><i>{((newRoom.country) ? getTaxonomyTermValueById(staticTaxonomyCountries, newRoom.country) : `-`)}</i></span>
										<label>Email: </label><span><i>{newRoom?.email}</i></span>
										<label>Provincia: </label><span><i>{((newRoom.province) ? getTaxonomyTermValueById(staticTaxonomyProvinces, newRoom.province) : `-`)}</i></span>
										<label>Teléfono: </label><span><i>{newRoom?.phone}</i></span>
										<label>Ciudad: </label><span><i>{((newRoom.city) ? getTaxonomyTermValueById(staticTaxonomyCities, newRoom.city) : `-`)}</i></span>
									</SimpleGrid>
								</Stack>
								<Stack direction='column' spacing='20px'>
									<SimpleGrid columns={{ base: 2 }} gap={{ base: '0px', md: '20px' }}>
										<label>Dirección: </label><span><i>{newRoom?.address}</i></span>
										<label>Descripción: </label><span><i>{newRoom?.description}</i></span>
									</SimpleGrid>
								</Stack>
								<Stack direction='column' spacing='20px'>
									<SimpleGrid columns={{ base: 2, md: 4 }} gap={{ base: '0px', md: '20px' }}>
										<label>Latitud: </label><span><i>{newRoom?.latitude}</i></span>
										<label>Longitud: </label><span><i>{newRoom?.longitude}</i></span>
										<Select
											id='status_id'
											w='unset'
											variant='transparent'
											display='flex'
											textColor={textColorPrimary}
											color={textColorPrimary}
											backgroundColor={backgroundColor}
											alignItems='center'
											placeholder='Estado'
											value={newRoom?.status_id}
											onChange={(e: any) => { changeHandler(e); }}
										>
											{staticTaxonomyRoomStatuses.sort(sorters.byName).map((element: iTaxonomyTerm) => (
												<option value={element.id}>{element.name}</option>
											))}
										</Select>
									</SimpleGrid>
								</Stack>
								<Flex justify='space-between' mt='24px'>
									<Button
										variant='light'
										fontSize='sm'
										borderRadius='16px'
										w={{ base: '128px', md: '148px' }}
										h='46px'
										onClick={() => geoTab.current.click()}>
										Anterior
									</Button>
									<Button
										variant='darkBrand'
										fontSize='sm'
										borderRadius='16px'
										w={{ base: '128px', md: '148px' }}
										h='46px'
										onClick={createRoom}>
										Dar de alta
									</Button>
								</Flex>
							</Flex>
						</Card>
					</TabPanel>
				</TabPanels>
			</Tabs>
		</Flex >
	);
}
