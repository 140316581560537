// Chakra imports
import { Button, Flex, FormControl, Text, useColorModeValue } from '@chakra-ui/react';
import Card from 'components/card/Card';
import InputField from 'components/fields/InputField';
import { useEffect, useState } from 'react';
import { updateSingleItem } from 'services/roomsService';
import { iRoom } from 'types/iRoom';

export default function Socials(props: {
	id: string;
	web: string;
	instagram: string;
	room: iRoom;
}) {
	const { id, room } = props;
	// Chakra Color Mode
	const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
	const textColorSecondary = 'secondaryGray.600';

	const [web, setWeb] = useState("");
	const [instagram, setInstagram] = useState("");
	const [roomData, setRoomData] = useState(room);

	useEffect(() => {
		setRoomData(room);
		setInstagram(room.instagram);
		setWeb(room.web);

	}, [room]);

	const saveSocials = async () => {
		const room: iRoom = {
			id: id,
			web: web,
			instagram: instagram
		}
		const savedSocials = await updateSingleItem(room);
		if (savedSocials === 200) {
			alert("Redes actualizadas correctamente")
		} else {
			alert("Ha ocurrido un problema, por favor, vuelve a intentar guardar");
		}
	}

	return (
		<FormControl>
			<Card mb='20px' pb='50px'>
				<Flex direction='column' mb='40px' ms='10px'>
					<Text fontSize='xl' color={textColorPrimary} fontWeight='bold'>
						Redes Sociales
					</Text>
					<Text fontSize='md' color={textColorSecondary}>
						Completa/Actualiza aquí las redes sociales de la empresa
					</Text>
				</Flex>
				<InputField mb='25px' id='twitter' label='Página web' placeholder='https://www.fobosescape.es' value={web} onChange={(e: any) => setWeb(e.target.value)} />
				<InputField mb='25px' id='instagram' label='Cuenta de Instagram' placeholder='@fobosescape' defaultValue={instagram} onChange={(e: any) => setInstagram(e.target.value)} />
				<Button variant='darkBrand' minW='183px' fontSize='sm' fontWeight='500' ms='auto' onClick={saveSocials}>
					Guardar
				</Button>
			</Card>
		</FormControl>
	);
}
