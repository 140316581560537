import { User } from "firebase/auth";
import { removeNotActive } from "libs/arrayHelper";
import { iRoomClaimed } from "types/iRoomClaimed";

async function getFullList(currentUser: User, fields?: string) {

    const currentToken = await currentUser.getIdToken()

    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': process.env.REACT_APP_FOBOS_API_KEY,
            'Authorization': 'Bearer ' + currentToken
        }
    }

    const tableFields = (fields ? "attributes=" + fields : "");
    let result = await fetch(process.env.REACT_APP_FOBOS_API_HOST + '/claims?' + tableFields, options)
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            return data;
        });
    while (result.lastKey && result.lastKey !== "false") {
        const iterativeResult = await fetch(process.env.REACT_APP_FOBOS_API_HOST + '/claims?' + tableFields + "&lastKey=" + result.lastKey, options)
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                return data;
            });
        result.data = [...result.data, ...iterativeResult.data];
        result.lastKey = iterativeResult.lastKey.id;
    }

    const cleanData = removeNotActive(result.data);

    return cleanData;
}

export { getFullList };

async function getSingleItem(currentUser: User, claimId: string) {

    const currentToken = await currentUser.getIdToken()

    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': process.env.REACT_APP_FOBOS_API_KEY,
            'Authorization': 'Bearer ' + currentToken
        }
    }

    return fetch(process.env.REACT_APP_FOBOS_API_HOST + '/claims/' + claimId, options)
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            return data;
        });
}

export { getSingleItem }

async function deleteSingleItem(currentUser: User, claimId: string) {

    const currentToken = await currentUser.getIdToken()

    const options = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': process.env.REACT_APP_FOBOS_API_KEY,
            'Authorization': 'Bearer ' + currentToken
        }
    }

    return fetch(process.env.REACT_APP_FOBOS_API_HOST + '/claims/' + claimId, options)
        .then((response) => {
            return response.status;
        })
        .then((data) => {
            return data;
        });
}

export { deleteSingleItem }

async function updateSingleItem(claim: iRoomClaimed) {
    const localStorageUser: User = JSON.parse(localStorage.getItem("user"));

    const options = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': process.env.REACT_APP_FOBOS_API_KEY,
            //@ts-ignore
            'Authorization': 'Bearer ' + localStorageUser.stsTokenManager.accessToken
        },
        body: JSON.stringify(claim)
    }

    return fetch(process.env.REACT_APP_FOBOS_API_HOST + '/claims/' + claim.id, options)
        .then((response) => {
            return response.status;
        })
        .then((data) => {
            return data;
        });
}

export { updateSingleItem }