// Chakra imports
import { Flex, Text, Avatar, useColorModeValue } from '@chakra-ui/react';
import Card from 'components/card/Card';
import { iRoom } from 'types/iRoom';

export default function RoomProfile(props: { room: iRoom, [x: string]: any }) {
	const { room } = props;
	const { ...rest } = props;
	const roomLogo = [process.env.REACT_APP_CDN_PUBLIC_IMAGES, room.image.replace('original', 'optimized')].join("/");

	const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
	const textColorSecondary = 'gray.400';
	// Chakra Color Mode
	return (
		<Card mb='20px' {...rest}>
			<Flex align='center'>
				<Avatar src={roomLogo} h='87px' w='87px' me='20px' />
				<Flex direction='column'>
					<Text color={textColorPrimary} fontWeight='bold' fontSize='2xl'>
						{room.name}
					</Text>
					<Text mt='1px' color={textColorSecondary} fontSize='md'>
						{room.email}
					</Text>
				</Flex>
			</Flex>
		</Card>
	);
}
