import { Button, Image, useDisclosure } from '@chakra-ui/react';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react'

function ImageComponent(props: any) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cdnUrl = process.env.REACT_APP_CDN_PUBLIC_IMAGES;
  const miniaturePath = "table-crop";
  const optimizedPath = "optimized";

  if (!props.value || props.value === null) {
    return <></>
  }

  const tableCropImgPath = props.value.replace("original", miniaturePath);
  const optimizedImgPath = props.value.replace("original", optimizedPath);

  return (
    <>
      <Image srcSet={[cdnUrl, tableCropImgPath].join('/')} maxH={39} maxWidth={`fit-content`} onClick={onOpen} />

      <Modal isOpen={isOpen} onClose={onClose} size='xl'>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{props.data.name}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Image srcSet={[cdnUrl, optimizedImgPath].join('/')} />
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={onClose}>
              Cerrar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default ImageComponent;