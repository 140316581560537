// Chakra imports
import { Text, useColorModeValue } from '@chakra-ui/react';
// Assets
import Project1 from 'assets/img/profile/Project1.png';
import Project2 from 'assets/img/profile/Project2.png';
import Project3 from 'assets/img/profile/Project3.png';
// Custom components
import Card from 'components/card/Card';
import Team from 'views/admin/profile/components/Team';

export default function Teams(props: { [x: string]: any }) {
	const { ...rest } = props;
	// Chakra Color Mode
	const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
	const textColorSecondary = 'gray.400';
	const cardShadow = useColorModeValue('0px 18px 40px rgba(112, 144, 176, 0.12)', 'unset');
	return (
		<Card mb={{ base: '0px', '2xl': '20px' }} {...rest}>
			<Text color={textColorPrimary} fontWeight='bold' fontSize='2xl' mt='10px' mb='4px'>
				Equipos
			</Text>
			<Text color={textColorSecondary} fontSize='md' me='26px' mb='40px'>
				Equipos a los que pertenece este usuari@
			</Text>
			<Team
				boxShadow={cardShadow}
				mb='20px'
				image={Project1}
				games='1'
				team_id='#'
				team_name='Cucos y Coquetos'
			/>
			<Team
				boxShadow={cardShadow}
				mb='20px'
				image={Project2}
				games='2'
				team_id='#'
				team_name='Coquetos sin Cucos (Abel y Elena)'
			/>
			<Team
				boxShadow={cardShadow}
				image={Project3}
				games='3'
				team_id='#'
				team_name='Los CucosZafer'
			/>
		</Card>
	);
}
