import { User } from "firebase/auth";
import { getUserAccessToken } from "libs/auth";
import { iTaxonomy } from "types/iTaxonomy";

async function getFullList(currentUser: User) {

    const currentToken = await currentUser.getIdToken()

    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': process.env.REACT_APP_FOBOS_API_KEY,
            'Authorization': 'Bearer ' + currentToken
        }
    }

    return fetch(process.env.REACT_APP_FOBOS_API_HOST + '/taxonomies', options)
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            return data.data;
        });
}

export { getFullList };

async function getSingleElement(currentUser: User, taxonomy_id: string) {

    const currentToken = await currentUser.getIdToken()

    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': process.env.REACT_APP_FOBOS_API_KEY,
            'Authorization': 'Bearer ' + currentToken
        }
    }

    return fetch(process.env.REACT_APP_FOBOS_API_HOST + '/taxonomies/' + taxonomy_id, options)
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            return data;
        });
}

export { getSingleElement };

async function getTermsFullList(currentUser: User, taxonomy_id: string) {

    const currentToken = await currentUser.getIdToken()

    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': process.env.REACT_APP_FOBOS_API_KEY,
            'Authorization': 'Bearer ' + currentToken
        }
    }

    return fetch(process.env.REACT_APP_FOBOS_API_HOST + '/taxonomies/' + taxonomy_id + '/terms', options)
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            return data.data;
        });
}

export { getTermsFullList };

async function createItem(currentUser: User, taxonomy: iTaxonomy) {
    const currentToken = await currentUser.getIdToken()

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': process.env.REACT_APP_FOBOS_API_KEY,
            'Authorization': 'Bearer ' + currentToken
        },
        body: JSON.stringify(taxonomy)
    }

    let result = await fetch(process.env.REACT_APP_FOBOS_API_HOST + `/taxonomies/`, options)
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            return data;
        });

    return result;
}

export { createItem }

async function deleteSingleItem(currentUser: User, taxonomyId: string) {

    const currentToken = await currentUser.getIdToken()

    const options = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': process.env.REACT_APP_FOBOS_API_KEY,
            'Authorization': 'Bearer ' + currentToken
        }
    }

    return fetch(process.env.REACT_APP_FOBOS_API_HOST + '/taxonomies/' + taxonomyId, options)
        .then((response) => {
            return response.status;
        })
        .then((data) => {
            return data;
        });
}

export { deleteSingleItem }
