// Chakra imports
import { Flex, Text, Avatar, useColorModeValue } from '@chakra-ui/react';
import Card from 'components/card/Card';
import { iGame } from 'types/iGame';
import { iRoom } from 'types/iRoom';

export default function PlayedGameHeader(props: { game: iGame, room: iRoom, [x: string]: any }) {
	const { game, room } = props;
	const { ...rest } = props;
	const gameLogo = [process.env.REACT_APP_CDN_PUBLIC_IMAGES, game.image].join("/");
	const roomLogo = [process.env.REACT_APP_CDN_PUBLIC_IMAGES, room.image].join("/");

	const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
	const textColorSecondary = 'gray.400';
	// Chakra Color Mode
	return (
		<Card mb='20px' {...rest}>
			<Flex align='center'>
				<Avatar src={gameLogo} h='87px' w='87px' me='20px' />
				<Avatar src={roomLogo} h='87px' w='87px' me='20px' />
				<Flex direction='column'>
					<Text color={textColorPrimary} fontWeight='bold' fontSize='2xl'>
						{game.name}
					</Text>
					<Text mt='1px' color={textColorSecondary} fontSize='md'>
						{room.name}
					</Text>
				</Flex>
			</Flex>
		</Card>
	);
}
