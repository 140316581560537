import { User } from "firebase/auth";

export function getUserAccessToken() {
    const localStorageUser: User = JSON.parse(localStorage.getItem("user"));
    //@ts-ignore
    const currentToken = localStorageUser.stsTokenManager.accessToken;
    return currentToken;
}

export function getUserRefreshToken() {
    const localStorageUser: User = JSON.parse(localStorage.getItem("user"));
    //@ts-ignore
    const refreshToken = localStorageUser.stsTokenManager.refreshToken;
    return refreshToken;
}