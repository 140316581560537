import { User } from "firebase/auth";
import { iGame } from "types/iGame";

async function getFullList(currentUser: User, fields?: string) {

    const currentToken = await currentUser.getIdToken()

    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': process.env.REACT_APP_FOBOS_API_KEY,
            'Authorization': 'Bearer ' + currentToken
        }
    }

    const tableFields = (fields ? "attributes=" + fields : "");
    let result = await fetch(process.env.REACT_APP_FOBOS_API_HOST + '/games?' + tableFields, options)
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            return data;
        });
    while (result.lastKey && result.lastKey !== "false") {
        const iterativeResult = await fetch(process.env.REACT_APP_FOBOS_API_HOST + '/games?' + tableFields + "&lastKey=" + result.lastKey, options)
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                return data;
            });
        result.data = [...result.data, ...iterativeResult.data];
        result.lastKey = iterativeResult.lastKey;
    }
    return result.data;
}

export { getFullList };

async function createItem(currentUser: User, game: iGame) {
    const currentToken = await currentUser.getIdToken()

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': process.env.REACT_APP_FOBOS_API_KEY,
            'Authorization': 'Bearer ' + currentToken
        },
        body: JSON.stringify(game)
    }

    let result = await fetch(process.env.REACT_APP_FOBOS_API_HOST + `/rooms/${game.room_id}/games`, options)
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            return data;
        });

    return result;
}

export { createItem }

async function getSingleItem(currentUser: User, gameId: string) {

    const currentToken = await currentUser.getIdToken();

    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': process.env.REACT_APP_FOBOS_API_KEY,
            'Authorization': 'Bearer ' + currentToken
        }
    }

    return fetch(process.env.REACT_APP_FOBOS_API_HOST + '/games/' + gameId, options)
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            return data;
        });
}

export { getSingleItem };

async function deleteSingleItem(currentUser: User, roomId: string, gameId: string) {

    const currentToken = await currentUser.getIdToken();

    const options = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': process.env.REACT_APP_FOBOS_API_KEY,
            'Authorization': 'Bearer ' + currentToken
        }
    }

    return fetch(process.env.REACT_APP_FOBOS_API_HOST + '/rooms/' + roomId + '/games/' + gameId, options)
        .then((response) => {
            return response.status;
        })
        .then((data) => {
            return data;
        });
}

export { deleteSingleItem }

async function updateSingleItem(currentUser: User, game: iGame) {

    const currentToken = await currentUser.getIdToken();

    const gameId = game.id;
    const roomId = game.room_id;

    delete game.id;
    delete game.room_id;

    const options = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': process.env.REACT_APP_FOBOS_API_KEY,
            'Authorization': 'Bearer ' + currentToken
        },
        body: JSON.stringify(game)
    }

    return fetch(process.env.REACT_APP_FOBOS_API_HOST + '/rooms/' + roomId + '/games/' + gameId, options)
        .then((response) => {
            return response.status;
        })
        .then((data) => {
            return data;
        });
}

export { updateSingleItem }