import React, { useState, useEffect, useContext } from 'react';

// Chakra imports
import { Box } from '@chakra-ui/react';

import RoomsTable from './components/RoomsTable';
import { getFullList } from 'services/roomsService';
import { AuthContext } from 'context/auth-context';
import { StaticDataContext } from 'context/static-data-context';
import { getTaxonomyTermValueById } from 'libs/arrayHelper';

interface iRoomTable {
	id: string;
	old_id?: string;
	name: string;
	description?: string;
	logo: string;
	email?: string;
	phone?: string;
	address?: string;
	country?: string;
	country_name?: string;
	province?: string;
	province_name?: string;
	city?: string;
	city_name?: string;
	instagram?: string;
	web?: string;
	latitude?: string;
	longitude?: string;
	status_id?: string;
	status_name?: string;
	is_active: boolean;
	created_at: string;
	created_by?: string;
	updated_at?: string;
	updated_by?: string;
}

export default function Rooms() {
	const { currentUser } = useContext(AuthContext);
	const { staticTaxonomyCities, staticTaxonomyProvinces, staticTaxonomyRoomStatuses } = useContext(StaticDataContext);

	const roomsList: iRoomTable[] = [];
	//State
	const [rooms, setRooms]: [iRoomTable[], (roomList: iRoomTable[]) => void] = useState(roomsList);
	const tableFields = "id,name,city,province,status_id,email,image";

	useEffect(() => {
		getFullList(currentUser, tableFields).then((roomsList: iRoomTable[]) => {
			roomsList.map(room => {

				if (room.city && room.city !== '-') {
					room.city_name = getTaxonomyTermValueById(staticTaxonomyCities, room.city);
				} else {
					room.city_name = "Sin Ciudad";
				}

				if (room.province && room.province !== '-') {
					room.province_name = getTaxonomyTermValueById(staticTaxonomyProvinces, room.province);
				} else {
					room.province_name = "Sin Provincia";
				}

				if (room.status_id && room.status_id !== '-') {
					room.status_name = getTaxonomyTermValueById(staticTaxonomyRoomStatuses, room.status_id);
				} else {
					room.status_name = "-";
				}

			});

			setRooms(roomsList)
		});
	}, []);

	return (
		<Box pt={{ base: '180px', md: '80px', xl: '80px' }}>
			{/* Main Fields */}
			<RoomsTable tableData={rooms} />
		</Box>
	);
}
