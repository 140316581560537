import React, { useState, useEffect, useContext } from 'react';

// Chakra imports
import { Box } from '@chakra-ui/react';

//Data presentation
import UsersTable from './components/UsersTable';
import { getFullList } from 'services/usersService';
import { iUser } from 'types/iUser';
import { AuthContext } from 'context/auth-context';

export default function Users() {

	const { currentUser } = useContext(AuthContext);

	const usersList: iUser[] = [];
	//State
	const [users, setUsers]: [iUser[], (roomList: iUser[]) => void] = useState(usersList);

	useEffect(() => {
		getFullList(currentUser).then((usersList: iUser[]) => {
			setUsers(usersList)
		});
	}, []);

	return (
		<Box pt={{ base: '180px', md: '80px', xl: '80px' }}>
			{/* Main Fields */}
			<UsersTable tableData={users} />
		</Box>
	);
}
