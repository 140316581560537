// Chakra imports
import { Avatar, Flex, Image, Select, Text, useColorModeValue } from '@chakra-ui/react';
import Card from 'components/card/Card';
import { StaticDataContext } from 'context/static-data-context';
import { useContext, useEffect, useState } from 'react';
import { sorters } from 'libs/arrayHelper';
import { iTaxonomyTerm } from 'types/iTaxonomyTerm';
import { updateSingleItem } from 'services/gamesService';
import { iGame } from 'types/iGame';
import { AuthContext } from 'context/auth-context';

export default function Profile(props: { game: iGame, banner: string }) {
	const { game, banner } = props;
	const { currentUser } = useContext(AuthContext);
	// Chakra Color Mode
	const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
	const textColorSecondary = 'secondaryGray.600';
	const backgroundColor = useColorModeValue('secondaryGray.200', 'fobos.darkGrey');

	const { staticTaxonomyRoomStatuses } = useContext(StaticDataContext);

	const [status_id, setStatus_id] = useState("");
	const [localGame, setLocalGame]: [iGame, (roomDetail: iGame) => void] = useState(game);
	const [gameLogo, setGameLogo] = useState("");

	const updateRoomStatus = async (status_id: string) => {
		setStatus_id(status_id);
		let tmpLocalGame = localGame;
		localGame.status_id = status_id;
		setLocalGame(tmpLocalGame);
		const game: iGame = {
			id: localGame.id,
			status_id: status_id, 
			room_id: localGame.room_id
		}
		const updatedStatus = await updateSingleItem(currentUser, game); 
		if (updatedStatus === 200) {
			alert("Estado actualizado correctamente")
		} else {
			alert("Ha ocurrido un problema, por favor, vuelve a intentar guardar");
		}
	}

	useEffect(() => {
		setLocalGame(game);
		if (game.image)
			setGameLogo([process.env.REACT_APP_CDN_PUBLIC_IMAGES, game.image.replace('original', 'optimized')].join("/"));
	});


	return (
		<Card mb='20px' alignItems='center'>
			<Image src={banner} borderRadius='16px' />
			<Avatar mx='auto' src={gameLogo} h='87px' w='87px' mt='-43px' mb='15px' />
			<Text fontSize='2xl' textColor={textColorPrimary} fontWeight='700'>
				{game.name}
			</Text>
			<Flex align='center' mx='auto' px='15px'>
				<Text me='4px' color={textColorSecondary} fontSize='sm' fontWeight='400' lineHeight='100%'>
					Estado del juego:
				</Text>

				<Select
					id='status'
					w='unset'
					variant='transparent'
					display='flex'
					textColor={textColorPrimary}
					color={textColorPrimary}
					backgroundColor={backgroundColor}
					alignItems='center'
					placeholder='Estado'
					value={game.status_id} onChange={(e: any) => updateRoomStatus(e.target.value)}
				>
					{staticTaxonomyRoomStatuses.sort(sorters.byName).map((element: iTaxonomyTerm) => (
						<option value={element.id}>{element.name}</option>
					))}
				</Select>
			</Flex>
		</Card>
	);
}
