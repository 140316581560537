import React, { useState, useEffect, useContext } from 'react';

// Chakra imports
import { Box, Grid } from '@chakra-ui/react';

import banner from 'assets/img/backgrounds/banner.png';
// Custom components
import Banner from 'views/admin/profile/components/Banner';
import General from 'views/admin/profile/components/General';
import Notifications from 'views/admin/profile/components/Notifications';
import Teams from 'views/admin/profile/components/Teams';
import Storage from 'views/admin/profile/components/Storage';
import Upload from 'views/admin/profile/components/Upload';

//React routing
//@ts-ignore
import { Await, createBrowserRouter, defer, Form, Link, Outlet, RouterProvider, useAsyncError, useAsyncValue, useFetcher, useFetchers, useLoaderData, useNavigation, useParams, useRevalidator, useRouteError, } from "react-router-dom";

//Auth
import { AuthContext } from 'context/auth-context';

//API Calls
import { getUser } from 'services/usersService';
import { iProfile } from 'types/iProfile';
import ProfileCompletion from './components/ProfileCompletion';


export default function Overview() {

	const { currentUser } = useContext(AuthContext);
	const params: any = useParams();

	//State
	const defaultProfile: iProfile = {
		id: '',
		email: 'fobito@fobosescape.com',
		roles: 'ROLE_USER',
		salt: '',
		is_active: false,
		created_at: '',
		provider: '',
		provider_id: '',
		name: 'Cargando',
		surname: '...',
		gender: '...'
	}
	const [user, setUser]: [iProfile, (user: iProfile) => void] = useState(defaultProfile);

	useEffect(() => {
		getUser(currentUser, params.user_id).then((user: iProfile) => {
			setUser(user)
		});
	}, []);

	return (
		<Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
			{/* Main Fields */}
			<Grid
				templateColumns={{
					base: '1fr',
					lg: '1.34fr 1fr 1.62fr'
				}}
				templateRows={{
					base: 'repeat(3, 1fr)',
					lg: '1fr'
				}}
				gap={{ base: '20px', xl: '20px' }}>
				<Banner
					gridArea='1 / 1 / 2 / 2'
					banner={banner}
					avatar='https://fobosapp.com/public/upload/avatars/53.png'
					name={`${user.name} ${user.surname}`}
					role={user.gender}
					games='17'
					verifications='9.7k'
					whishlist='274'
				/>
				<ProfileCompletion gridArea={{ base: '2 / 1 / 3 / 2', lg: '1 / 2 / 2 / 3' }} completed={25.6} />
				<Upload
					gridArea={{
						base: '3 / 1 / 4 / 2',
						lg: '1 / 3 / 2 / 4'
					}}
					minH={{ base: 'auto', lg: '420px', '2xl': '365px' }}
					pe='20px'
					pb={{ base: '100px', lg: '20px' }}
				/>
			</Grid>
			<Grid
				mb='20px'
				templateColumns={{
					base: '1fr',
					lg: 'repeat(2, 1fr)',
					'2xl': '1.34fr 1.62fr 1fr'
				}}
				templateRows={{
					base: '1fr',
					lg: 'repeat(2, 1fr)',
					'2xl': '1fr'
				}}
				gap={{ base: '20px', xl: '20px' }}>
				<Teams />
				<General gridArea={{ base: '2 / 1 / 3 / 2', lg: '1 / 2 / 2 / 3' }} minH='365px' pe='20px' />
				<Notifications
					used={25.6}
					total={50}
					gridArea={{
						base: '3 / 1 / 4 / 2',
						lg: '2 / 1 / 3 / 3',
						'2xl': '1 / 3 / 2 / 4'
					}}
				/>
			</Grid>
		</Box>
	);
}
