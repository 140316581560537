
// Chakra imports
import { Box, Flex, Icon, SimpleGrid, useColorModeValue } from '@chakra-ui/react';
// Assets
import banner from 'assets/img/backgrounds/banner.png';
import { AuthContext } from 'context/auth-context';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getSingleItem } from 'services/roomsService';
import { getSingleItem as getMetrics } from 'services/metricsService';
import { iRoom } from 'types/iRoom';

// Custom components
import Info from 'views/admin/rooms/detail/components/Info';
import Profile from 'views/admin/rooms/detail/components/Profile';
import Socials from 'views/admin/rooms/detail/components/Socials';
import MiniStatistics from 'components/card/MiniStatistics';
import IconBox from 'components/icons/IconBox';


// Assets
import { IoAnalyticsOutline } from 'react-icons/io5';
import { GiPlayerTime, GiPuzzle } from 'react-icons/gi';
import { iMetric } from 'types/iMetric';

export default function Settings() {

	const { currentUser } = useContext(AuthContext);
	const params: any = useParams();

	// Chakra Color Mode
	const brandColor = useColorModeValue('brand.500', 'white');
	const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');

	const roomDetail: iRoom = {
		id: '',
		name: '',
		image: '',
		is_active: false,
		created_at: '',
		latitude: '0',
		longitude: '0'
	};
	//State
	const [room, setRoom]: [iRoom, (roomDetail: iRoom) => void] = useState(roomDetail);
	const [roomMetrics, setRoomMetrics]: [iMetric, (metric: iMetric) => void] = useState({});

	useEffect(() => {
		getSingleItem(currentUser, params['room_id']).then((roomDetail: iRoom) => {
			setRoom(roomDetail)
		});

		getMetrics(currentUser, "room:" + params['room_id']).then((metrics: iMetric) => {
			setRoomMetrics(metrics);
		})
	}, []);

	return (
		<Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
			<SimpleGrid columns={{ base: 1, md: 2, lg: 3, '2xl': 6 }} gap='20px' mb='20px'>
				<MiniStatistics
					startContent={
						<IconBox
							w='56px'
							h='56px'
							bg={boxBg}
							icon={<Icon w='32px' h='32px' as={GiPuzzle} color={brandColor} />}
						/>
					}
					name='Juegos'
					value={roomMetrics?.games || 0}
				/>
				<MiniStatistics
					startContent={
						<IconBox
							w='56px'
							h='56px'
							bg={boxBg}
							icon={<Icon w='32px' h='32px' as={GiPlayerTime} color={brandColor} />}
						/>
					}
					name='Partidas'
					value={roomMetrics?.played_games || 0}
				/>
				<MiniStatistics
					startContent={
						<IconBox
							w='56px'
							h='56px'
							bg='linear-gradient(90deg, #F1505E 0%, #f6969e 100%)'
							icon={<Icon w='28px' h='28px' as={IoAnalyticsOutline} color='white' />}
						/>
					}
					name='Media'
					value={roomMetrics?.average || 0}
				/>
			</SimpleGrid>
			<SimpleGrid mb='20px' columns={{ sm: 1, lg: 2 }} spacing={{ base: '20px', xl: '20px' }}>
				{/* Column Left */}
				<Flex direction='column'>
					<Profile room={room} name={room.name} avatar={room.image} banner={banner} id={room.id} status={room.status} />
					<Socials room={room} web={room.web} instagram={room.instagram} id={room.id} />
				</Flex>
				{/* Column Right */}
				<Flex direction='column'>
					<Info room={room} />
				</Flex>
			</SimpleGrid>
		</Box>
	);
}
