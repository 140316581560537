import { useHistory } from 'react-router-dom';
import { Flex, Box, Text, useColorModeValue, Icon, Button } from '@chakra-ui/react';
import { MdAddCircle } from 'react-icons/md';

// Custom components
import Card from 'components/card/Card';

// AG Grid
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import ActionsComponent from 'components/ag-tables/ActionsComponent';
import { iTaxonomy } from 'types/iTaxonomy';

export default function TaxonomyTermsTable(props: { tableData: any, taxonomyData: iTaxonomy }) {
	const history = useHistory();
	const { tableData, taxonomyData } = props;
	const { ...rest } = props;
	const iconColor = useColorModeValue('brand.500', 'white');
	const bgHover = useColorModeValue({ bg: 'secondaryGray.400' }, { bg: 'whiteAlpha.50' });
	const bgFocus = useColorModeValue({ bg: 'secondaryGray.400' }, { bg: 'whiteAlpha.100' });
	const bgButton = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
	const textColor = useColorModeValue('secondaryGray.900', 'white');

	//AG GRID Table definition
	const taxonomiesColumnDefs = [
		{ field: 'color', headerName: 'Color', sortable: false, filter: false, unSortIcon: false, floatingFilter: false, maxWidth: 100 },
		{ field: 'name', headerName: 'Nombre' },
		{ field: 'key', headerName: 'Clave' },
		{ field: 'actions', headerName: 'Acciones', sortable: false, filter: false, unSortIcon: false, floatingFilter: false, cellRenderer: ActionsComponent },
	];

	const defaultColDef = {
		flex: 1,
		sortable: true,
		filter: true,
		unSortIcon: true,
		floatingFilter: true,
		resizable: true
	};
	const goToCreateTaxonomyTerm = (taxonomyId: string) => {
		history.push(`/admin/taxonomies/${taxonomyId}/create`)
	}

	return (
		<Card flexDirection='column' w='100%' px='0px' overflowX={{ sm: 'scroll', lg: 'hidden' }}>
			<Flex px='25px' mb="8px" justifyContent='space-between' align='center'>
				<Text color={textColor} fontSize='22px' mb="4px" fontWeight='700' lineHeight='100%'>
					Elementos de la taxonomía '{taxonomyData.name}'
				</Text>
				<Button
					alignItems='center'
					justifyContent='center'
					bg={bgButton}
					_hover={bgHover}
					_focus={bgFocus}
					_active={bgFocus}
					w='37px'
					h='37px'
					lineHeight='100%'
					borderRadius='10px'
					{...rest}
					onClick={() => goToCreateTaxonomyTerm(taxonomyData.id)}>
					<Icon as={MdAddCircle} color={iconColor} w='24px' h='24px' />
				</Button>
			</Flex>
			<Box>
				<div id="taxonomy-terms-ag-grid" className='hero ag-theme-alpine' style={{ height: 700 }}>
					<AgGridReact
						gridId='taxonomies-terms'
						rowData={tableData}
						columnDefs={taxonomiesColumnDefs}
						defaultColDef={defaultColDef}
						animateRows={true}
						overlayNoRowsTemplate='<span style="padding: 10px; border: 2px solid #444;">Cargando información...</span>'
						onFilterChanged={(event: any) => {
							let filterModel = event.api.getFilterModel();
							localStorage.setItem('taxonomyTermsFilterModel', JSON.stringify(filterModel));
						}}
						onFirstDataRendered={(params: any) => {
							const filterModel = JSON.parse(localStorage.getItem('taxonomyTermsFilterModel') || '{}');
							params.api.setFilterModel(filterModel);

						}}
						components={{ ActionsComponent }}
					/>
				</div>
			</Box>
		</Card>
	);
} 