// Chakra imports
import { Avatar, Flex, Image, Select, Text, useColorModeValue } from '@chakra-ui/react';
import Card from 'components/card/Card';
import { StaticDataContext } from 'context/static-data-context';
import { useContext, useEffect, useState } from 'react';
import { sorters } from 'libs/arrayHelper';
import { iTaxonomyTerm } from 'types/iTaxonomyTerm';
import { updateSingleItem } from 'services/roomsService';
import { iRoom } from 'types/iRoom';

export default function Settings(props: { room: iRoom, name: string; avatar: string; banner: string; id: string, status: string }) {
	const { name, avatar, banner, id, status, room } = props;
	// Chakra Color Mode
	const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
	const textColorSecondary = 'secondaryGray.600';

	const { staticTaxonomyRoomStatuses } = useContext(StaticDataContext);

	const [localRoom, setLocalRoom]: [iRoom, (roomDetail: iRoom) => void] = useState(room);

	const roomLogo = [process.env.REACT_APP_CDN_PUBLIC_IMAGES, room.image.replace('original', 'optimized')].join("/");

	const updateRoomStatus = async (status_id: string) => {

		const updateRoom: iRoom = {
			id: id,
			status_id: status_id
		}
		const updatedStatus: any = await updateSingleItem(updateRoom);
		if (updatedStatus === 200) {
			alert("Actualizado");
			let tmpRoom = localRoom;
			tmpRoom.status_id = status_id;
			setLocalRoom(tmpRoom);
		}
	}

	useEffect(() => {
		setLocalRoom(room);
	}, []);


	return (
		<Card mb='20px' alignItems='center'>
			<Image src={banner} borderRadius='16px' />
			<Avatar mx='auto' src={roomLogo} h='87px' w='87px' mt='-43px' mb='15px' />
			<Text fontSize='2xl' textColor={textColorPrimary} fontWeight='700'>
				{room.name}
			</Text>
			<Flex align='center' mx='auto' px='15px'>
				<Text me='4px' color={textColorSecondary} fontSize='sm' fontWeight='400' lineHeight='100%'>
					Estado de la empresa:
				</Text>

				<Select
					id='status_id'
					w='unset'
					variant='transparent'
					display='flex'
					textColor={textColorPrimary}
					color={textColorPrimary}
					alignItems='center'
					placeholder='Estado'
					value={localRoom?.status_id}
					onChange={(e: any) => updateRoomStatus(e.target.value)}
				>
					{staticTaxonomyRoomStatuses.sort(sorters.byName).map((element: iTaxonomyTerm) => (
						<option value={element.id}>{element.name}</option>
					))}
				</Select>
			</Flex>
		</Card>
	);
}
