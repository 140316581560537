import { User } from "firebase/auth";
import { iRating } from "types/iRating";
import { iUser } from "types/iUser";

async function getFullList(currentUser: User, fields?: string) {

    const currentToken = await currentUser.getIdToken();

    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': process.env.REACT_APP_FOBOS_API_KEY,
            'Authorization': 'Bearer ' + currentToken
        }
    }

    const tableFields = (fields ? "attributes=" + fields : "");
    let result = await fetch(process.env.REACT_APP_FOBOS_API_HOST + '/played-games?' + tableFields, options)
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            return data;
        });

    while (result.lastKey && result.lastKey !== "false") {
        const iterativeResult = await fetch(process.env.REACT_APP_FOBOS_API_HOST + '/played-games?' + tableFields + "&lastKey=" + result.lastKey, options)
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                return data;
            });
        result.data = [...result.data, ...iterativeResult.data];
        result.lastKey = iterativeResult.lastKey;
    }

    return result.data;
}

export { getFullList };

async function updateSingleItem(currentUser: User, playedGame: iRating) {

    const currentToken = await currentUser.getIdToken();
    const playedGameId = playedGame.id;

    delete playedGame.id;

    const options = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': process.env.REACT_APP_FOBOS_API_KEY,
            'Authorization': 'Bearer ' + currentToken
        },
        body: JSON.stringify(playedGame)
    }

    return fetch(process.env.REACT_APP_FOBOS_API_HOST + '/reviews/' + playedGameId, options)
        .then((response) => {
            return response;
        })
        .then((data) => {
            return data;
        });
}

export { updateSingleItem }