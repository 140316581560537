import { Flex, Box, Text, useColorModeValue, Button, Icon } from '@chakra-ui/react';

// AG Grid
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import ImageComponent from 'components/ag-tables/ImageComponent';
import ActionsComponent from 'components/ag-tables/ActionsComponent';

// Custom components
import Card from 'components/card/Card';
import { MdAddCircle } from 'react-icons/md';
import { useHistory } from 'react-router-dom';

// const columns = columnsDataCheck;
export default function RoomsTable(props: { tableData: any }) {
	const history = useHistory();
	const { tableData } = props;
	const { ...rest } = props;
	const textColor = useColorModeValue('secondaryGray.900', 'white');
	const iconColor = useColorModeValue('brand.500', 'white');
	const bgHover = useColorModeValue({ bg: 'secondaryGray.400' }, { bg: 'whiteAlpha.50' });
	const bgFocus = useColorModeValue({ bg: 'secondaryGray.400' }, { bg: 'whiteAlpha.100' });
	const bgButton = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');

	//AG GRID Table definition
	const roomsColumnDefs = [
		{ field: 'image', headerName: 'Logo', sortable: false, filter: false, unSortIcon: false, floatingFilter: false, maxWidth: 100, cellRenderer: ImageComponent },
		{ field: 'name', headerName: 'Nombre' },
		{ field: 'city_name', headerName: 'Ciudad', width: 150 },
		{ field: 'province_name', headerName: 'Provincia', width: 150 },
		{ field: 'email', headerName: 'Email', width: 250 },
		{ field: 'status_name', headerName: 'Estado', width: 250 },
		{ field: 'actions', headerName: 'Acciones', sortable: false, filter: false, unSortIcon: false, floatingFilter: false, cellRenderer: ActionsComponent },
	];

	const defaultColDef = {
		flex: 1,
		sortable: true,
		filter: true,
		unSortIcon: true,
		floatingFilter: true,
		resizable: true
	};
	//End of AG Table Definition

	const goToCreateRoom = () => {
		history.push('/admin/rooms/create')
	}

	return (
		<Card flexDirection='column' w='100%' px='0px' overflowX={{ sm: 'scroll', lg: 'hidden' }}>
			<Flex px='25px' mb="8px" justifyContent='space-between' align='center'>
				<Text color={textColor} fontSize='22px' mb="4px" fontWeight='700' lineHeight='100%'>
					Listado de salas
				</Text>
				<Button
					alignItems='center'
					justifyContent='center'
					bg={bgButton}
					_hover={bgHover}
					_focus={bgFocus}
					_active={bgFocus}
					w='37px'
					h='37px'
					lineHeight='100%'
					borderRadius='10px'
					{...rest}
					onClick={goToCreateRoom}>
					<Icon as={MdAddCircle} color={iconColor} w='24px' h='24px' />
				</Button>
			</Flex>
			<Box>
				<div id="rooms-ag-grid" className='hero ag-theme-alpine' style={{ height: 700 }}>
					<AgGridReact
						gridId='rooms'
						rowData={tableData}
						columnDefs={roomsColumnDefs}
						defaultColDef={defaultColDef}
						overlayLoadingTemplate='<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>'
						overlayNoRowsTemplate='<span style="padding: 10px; border: 2px solid #444;">Cargando información...</span>'
						animateRows={true}
						onFilterChanged={(event: any) => {
							let filterModel = event.api.getFilterModel();
							localStorage.setItem('roomsFilterModel', JSON.stringify(filterModel));
						}}
						onFirstDataRendered={(params: any) => {
							const filterModel = JSON.parse(localStorage.getItem('roomsFilterModel') || '{}');
							params.api.setFilterModel(filterModel);
							// Hide columns in mobile view
							if (window.innerWidth < 786) {
								params.columnApi.setColumnVisible("city", false);
								params.columnApi.setColumnVisible("province", false);
								params.columnApi.setColumnVisible("email", false);
							}
						}}
						components={{ ImageComponent, ActionsComponent }}

					/>
				</div>
			</Box>

		</Card>
	);
} 