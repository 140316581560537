function LoadingComponent(props: any) {
    return (
        <>
            <div >
                <i ></i>
                <span>Cargando registros...</span>
            </div>
        </>
    );

}

export default LoadingComponent;