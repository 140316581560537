// Chakra imports
import { Box, Flex, Icon, Image, Link, Text, useColorModeValue } from '@chakra-ui/react';
// Custom components
import Card from 'components/card/Card';
// Assets
import { MdEdit } from 'react-icons/md';

export default function Team(props: {
	team_name: string;
	games: number | string;
	team_id: string;
	image: string;
	[x: string]: any;
}) {
	const { team_name, games, team_id, image, ...rest } = props;
	// Chakra Color Mode
	const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
	const textColorSecondary = 'gray.400';
	const brandColor = useColorModeValue('brand.500', 'white');
	const bg = useColorModeValue('white', 'fobos.darkGrey');
	return (
		<Card bg={bg} {...rest} p='14px'>
			<Flex align='center' direction={{ base: 'column', md: 'row' }}>
				<Image h='80px' w='80px' src={image} borderRadius='8px' me='20px' />
				<Box mt={{ base: '10px', md: '0' }}>
					<Text color={textColorPrimary} fontWeight='500' fontSize='md' mb='4px'>
						{team_name}
					</Text>
					<Text fontWeight='500' color={textColorSecondary} fontSize='sm' me='4px'>
						{games} {' '} Partida{games > 1 ? 's' : ''} •{' '}
						<Link fontWeight='500' color={brandColor} href={team_id} fontSize='sm'>
							Ver {games > 1 ? 'todas' : ''}
						</Link>
					</Text>
				</Box>

			</Flex>
		</Card>
	);
}
