// Chakra imports
import {
	Box,
	Button,
	Flex,
	SimpleGrid,
	Stack,
	Text,
	useColorModeValue
} from '@chakra-ui/react';

// Custom components
import Card from 'components/card/Card';
import InputField from 'components/fields/InputField';
import React, { useContext, useState } from 'react';

// Assets
import banner from 'assets/img/backgrounds/banner.png';
import { StaticDataContext } from 'context/static-data-context';
import { getTaxonomyTermValueById, sorters } from 'libs/arrayHelper';
import { iTaxonomy } from 'types/iTaxonomy';
import { iTaxonomyTerm } from 'types/iTaxonomyTerm';
import { createItem } from 'services/taxonomiesService';
import { AuthContext } from 'context/auth-context';

export default function NewTaxonomy() {
	const { currentUser } = useContext(AuthContext);

	//Taxonomy fields
	const [name, setName] = useState("");
	const [color, setColor] = useState("");
	const [newTaxonomy, setNewTaxonomy] = useState()

	const defaultTaxonomy: iTaxonomy = {};

	const createTaxonomy = async () => {
		const newTaxonomy: iTaxonomy = {
			//_et: "taxonomies",
			name: name,
			key: name.toLowerCase().replaceAll(" ", "-"),
			color: color,
		}
		const createdTaxonomy = await createItem(currentUser, newTaxonomy);
		if (createdTaxonomy?.id) {
			alert("Taxonomía creada correctamente")
		} else {
			alert("Ha ocurrido un problema, por favor, vuelve a intentar crear la taxonomía");
		}
	}

	const textColor = useColorModeValue('secondaryGray.900', 'white');

	return (
		<Flex direction='column' minH='100vh' align='center' pt={{ sm: '125px', lg: '75px' }} position='relative'>
			<Box
				h='45vh'
				bg='linear-gradient(135deg, #868CFF 0%, #4318FF 100%)'
				bgImage={banner}
				position='absolute'
				w='100%'
				borderRadius='30px'
			/>
			<Card p='30px'>
				<Text color={textColor} fontSize='2xl' fontWeight='700' mb='20px'>
					Datos para creación de la taxonomía
				</Text>
				<Flex direction='column' w='100%'>
					<SimpleGrid columns={{ base: 1, md: 2 }} gap='20px'>
						<Stack direction='column' gap='20px'>
							<InputField
								mb='0px'
								id='name'
								placeholder='ej: Tipos de candado'
								label='Nombre'
								defaultValue={name}
								onChange={(e: any) => setName(e.target.value)}
							/>
							<InputField
								mb='0px'
								id='phone'
								placeholder='ej: #FFFFFF'
								label='Color'
								defaultValue={color}
								onChange={(e: any) => setColor(e.target.value)}
							/>
						</Stack>
					</SimpleGrid>

					<Flex justify='space-between' mt='24px'>
						<Button
							variant='darkBrand'
							fontSize='sm'
							borderRadius='16px'
							w={{ base: '128px', md: '148px' }}
							h='46px'
							ms='auto'
							onClick={createTaxonomy}>
							Crear
						</Button>
					</Flex>
				</Flex>
			</Card>


		</Flex >
	);
}
