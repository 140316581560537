import React, { useState, useEffect, useContext } from 'react';

// Chakra imports
import { Box } from '@chakra-ui/react';

import RoomsClaimTable from './components/RoomsClaimTable';

import { getFullList } from 'services/claimsService';
import { AuthContext } from 'context/auth-context';
import { iRoomClaimed } from 'types/iRoomClaimed';
import { StaticDataContext } from 'context/static-data-context';

export default function RoomsClaim() {
	const { currentUser } = useContext(AuthContext);
	const { staticRooms } = useContext(StaticDataContext);
	const roomsClaimedList: iRoomClaimed[] = [];
	//State
	const [roomsClaimed, setRoomsClaimed]: [iRoomClaimed[], (roomClaimedList: iRoomClaimed[]) => void] = useState(roomsClaimedList);
	const tableFields = "id,name,email,phone,room_id,created_at,is_active,is_verified";
	useEffect(() => {

		getFullList(currentUser, tableFields).then((roomsClaimedList: iRoomClaimed[]) => {
			roomsClaimedList.map(claim => {
				if (claim.room_id) {
					const roomId = claim.room_id || "";
					const roomPos = staticRooms.map(e => e.id).indexOf(roomId);
					claim.room_name = staticRooms[roomPos].name;
				}
			})
			setRoomsClaimed(roomsClaimedList)
		});
	}, []);

	return (
		<Box pt={{ base: '180px', md: '80px', xl: '80px' }}>
			{/* Main Fields */}
			<RoomsClaimTable tableData={roomsClaimed} />
		</Box>
	);
}
