import { User } from "firebase/auth";
import { getUserAccessToken } from "libs/auth";
import { iRoom } from "types/iRoom";

async function getFullList(currentUser: User, fields?: string) {

    const currentToken = getUserAccessToken();

    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': process.env.REACT_APP_FOBOS_API_KEY,
            'Authorization': 'Bearer ' + currentToken
        }
    }

    const tableFields = (fields ? "attributes=" + fields : "");
    let result = await fetch(process.env.REACT_APP_FOBOS_API_HOST + '/rooms?' + tableFields, options)
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            return data;
        });

    while (result.lastKey && result.lastKey !== "false") {
        const iterativeResult = await fetch(process.env.REACT_APP_FOBOS_API_HOST + '/rooms?' + tableFields + "&lastKey=" + result.lastKey, options)
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                return data;
            });
        result.data = [...result.data, ...iterativeResult.data];
        result.lastKey = iterativeResult.lastKey;
    }

    return result.data;
}

export { getFullList };

async function getSingleItem(currentUser: User, roomId: string) {

    const currentToken = getUserAccessToken();

    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': process.env.REACT_APP_FOBOS_API_KEY,
            'Authorization': 'Bearer ' + currentToken
        }
    }

    return fetch(process.env.REACT_APP_FOBOS_API_HOST + '/rooms/' + roomId, options)
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            return data;
        });
}

export { getSingleItem };

async function deleteSingleItem(roomId: string) {

    const currentToken = getUserAccessToken();

    const options = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': process.env.REACT_APP_FOBOS_API_KEY,
            'Authorization': 'Bearer ' + currentToken
        }
    }

    return fetch(process.env.REACT_APP_FOBOS_API_HOST + '/rooms/' + roomId, options)
        .then((response) => {
            return response.status;
        })
        .then((data) => {
            return data;
        });
}

export { deleteSingleItem }

async function updateSingleItem(room: iRoom) {

    const currentToken = getUserAccessToken();
    const roomId = room.id;

    delete room.id;

    const options = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': process.env.REACT_APP_FOBOS_API_KEY,
            //@ts-ignore
            'Authorization': 'Bearer ' + currentToken
        },
        body: JSON.stringify(room)
    }

    return fetch(process.env.REACT_APP_FOBOS_API_HOST + '/rooms/' + roomId, options)
        .then((response) => {
            return response.status;
        })
        .then((data) => {
            return data;
        });
}

export { updateSingleItem }

async function createItem(currentUser: User, room: iRoom) {
    const currentToken = await currentUser.getIdToken()

    delete room.id;

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': process.env.REACT_APP_FOBOS_API_KEY,
            'Authorization': 'Bearer ' + currentToken
        },
        body: JSON.stringify(room)
    }

    let result = await fetch(process.env.REACT_APP_FOBOS_API_HOST + `/rooms`, options)
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            return data;
        });
    console.log(result)
    return result;
}

export { createItem }