import { Button, Image, useDisclosure, Text, Center, SimpleGrid } from '@chakra-ui/react';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react'
import { AuthContext } from 'context/auth-context';
import { useContext, useEffect, useState } from 'react';
import { getSingleItem as getSingleRoom } from 'services/roomsService';
import { getSingleItem as getSingleGame } from 'services/gamesService';
import { iGame } from 'types/iGame';
import { iRoom } from 'types/iRoom';
import PlayedGameHeader from 'views/admin/ratings/components/GameProfile';
import { updateSingleItem } from 'services/playedGamesService';
import { iRating } from 'types/iRating';

function PlayedGameImageComponent(props: any) {
  const { currentUser } = useContext(AuthContext);
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cdnUrl = process.env.REACT_APP_CDN_PUBLIC_IMAGES;
  const miniaturePath = "table-crop";
  const optimizedPath = "optimized";
  const defaultRoom: iRoom = {
    id: ''
  }
  const [room, setRoom]: [iRoom, (element: iRoom) => void] = useState(defaultRoom);
  const defaultGame: iGame = {}
  const [game, setGame]: [iGame, (element: iGame) => void] = useState(defaultGame);
  const [playedGame, setPlayedGame]: [iRating, (element: iRating) => void] = useState(props.data);

  useEffect(() => {
    props.data.is_validated = playedGame.is_verified
    console.log("patata")
    console.log(playedGame)
  }, [playedGame]);

  if (!props.value || props.value === null) {
    return <></>
  }

  const tableCropImgPath = props.value.replace("original", miniaturePath);
  const optimizedImgPath = props.value.replace("original", optimizedPath);

  const playerName = playedGame.user_nickname;
  const playerTeam = (playedGame.team_id ? <Text as='i'>({playedGame.team_name})</Text> : <></>)

  let gameProfileComponent;
  if (game.name && room.name) {
    gameProfileComponent = <PlayedGameHeader game={game} room={room} />
  } else {
    gameProfileComponent = null
  }

  const showOverlay = () => {
    getSingleRoom(currentUser, playedGame.room_id).then((room: iRoom) => {
      setRoom(room);
    });
    getSingleGame(currentUser, playedGame.game_id).then((game: iGame) => {
      setGame(game);
    });
    onOpen();
  }

  let actionButton = ((!playedGame.is_verified) ? <Button colorScheme='green' onClick={(e: any) => verifyPlayedGame(true)}>Verificar </Button> : <Button colorScheme='red' onClick={(e: any) => verifyPlayedGame(false)}>Des-Verificar </Button>);

  const verifyPlayedGame = async (verification: boolean) => {
    const updatePlayedGame: iRating = {
      id: playedGame.id,
      is_verified: verification
    }

    const response = await updateSingleItem(currentUser, updatePlayedGame);
    if (response.status === 200) {
      let tmpPlayedGame = playedGame;
      tmpPlayedGame.is_verified = verification;
      setPlayedGame(tmpPlayedGame);
      alert("Partida actualizada correctamente");
    } else {
      alert("Ha ocurrido un problema, vuelve a intentarlo");
    }
  }


  return (
    <>
      <Image srcSet={[cdnUrl, tableCropImgPath].join('/')} maxH={39} maxWidth={`fit-content`} onClick={showOverlay} />

      <Modal isOpen={isOpen} onClose={onClose} isCentered size='xl'>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Imagen de la partida de {playerName} {playerTeam}</ModalHeader>
          <ModalCloseButton />
          <ModalBody css={'text-align:center'}>
            {gameProfileComponent}
            <Image display={'inline-block'} srcSet={[cdnUrl, optimizedImgPath].join('/')} />
            <SimpleGrid columns={{ base: 2, md: 4 }} gap={{ base: '0px', md: '20px' }}>
              <label>Inmersión: </label><span><i>{playedGame.immersion}</i></span>
              <label>GM: </label><span><i>{playedGame.game_master}</i></span>
              <label>Historia: </label><span><i>{playedGame.history}</i></span>
              <label>Enigmas: </label><span><i>{playedGame.puzzles}</i></span>
              <label>Miedo: </label><span><i>{playedGame.fear}</i></span>
              <label>Dificultad: </label><span><i>{playedGame.difficulty}</i></span>
              <label>Media: </label><span><i>{playedGame.average}</i></span>
            </SimpleGrid>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='yellow' mr={3} onClick={onClose}>
              Cerrar
            </Button>
            {actionButton}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default PlayedGameImageComponent;