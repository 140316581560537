import { Flex, Box, Text, useColorModeValue } from '@chakra-ui/react';

// Custom components
import Card from 'components/card/Card';

// AG Grid
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import ActionsComponent from 'components/ag-tables/ActionsComponent';

export default function UsersTable(props: { tableData: any }) {
	const { tableData } = props;

	const textColor = useColorModeValue('secondaryGray.900', 'white');

	//AG GRID Table definition
	const usersColumnDefs = [
		{ field: 'email', headerName: 'Email' },
		{ field: 'nickname', headerName: 'Alias' },
		{ field: 'roles', headerName: 'Roles' },
		{ field: 'created_at', headerName: 'Fecha alta' },
		{ field: 'actions', headerName: 'Acciones', sortable: false, filter: false, unSortIcon: false, floatingFilter: false, cellRenderer: ActionsComponent },
	];

	const defaultColDef = {
		flex: 1,
		sortable: true,
		filter: true,
		unSortIcon: true,
		floatingFilter: true,
		resizable: true
	};

	return (
		<Card flexDirection='column' w='100%' px='0px' overflowX={{ sm: 'scroll', lg: 'hidden' }}>
			<Flex px='25px' mb="8px" justifyContent='space-between' align='center'>
				<Text color={textColor} fontSize='22px' mb="4px" fontWeight='700' lineHeight='100%'>
					Listado de usuarios
				</Text>

			</Flex>
			<Box>
				<div id="taxonomies-ag-grid" className='hero ag-theme-alpine' style={{ height: 700 }}>
					<AgGridReact
						gridId='users'
						rowData={tableData}
						columnDefs={usersColumnDefs}
						defaultColDef={defaultColDef}
						animateRows={true}
						overlayNoRowsTemplate='<span style="padding: 10px; border: 2px solid #444;">Cargando información...</span>'
						onFilterChanged={(event: any) => {
							let filterModel = event.api.getFilterModel();
							localStorage.setItem('usersFilterModel', JSON.stringify(filterModel));
						}}
						onFirstDataRendered={(params: any) => {
							const filterModel = JSON.parse(localStorage.getItem('usersFilterModel') || '{}');
							params.api.setFilterModel(filterModel);

						}}
						components={{ ActionsComponent }}
					/>
				</div>
			</Box>
		</Card>
	);
} 