// Chakra imports
import { Flex, FormControl, SimpleGrid, Text, useColorModeValue } from '@chakra-ui/react';
import Card from 'components/card/Card';
import InputField from 'components/fields/InputField';
import TextField from 'components/fields/TextField';
import { iRoomClaimed } from 'types/iRoomClaimed';

export default function ClaimInformation(props: { claim: iRoomClaimed, [x: string]: any }) {
	const { claim } = props;
	const { ...rest } = props;
	const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
	const textColorSecondary = 'secondaryGray.600';
	// Chakra Color Mode
	return (
		<FormControl>
			<Card mb='20px' {...rest}>
				<Flex direction='column' mb='30px' ms='10px'>
					<Text fontSize='xl' color={textColorPrimary} fontWeight='bold'>
						Mensaje
					</Text>
					<Text fontSize='md' color={textColorSecondary}>
						Detalles sobre la petición
					</Text>
				</Flex>
				<InputField id='name' label='Nombre' placeholder='...' value={claim.name} />
				<SimpleGrid columns={{ sm: 1, md: 2 }} spacing={{ base: '20px', xl: '20px' }}>

					<InputField mb='0px' id='email' label='Email ' placeholder='...' value={claim.email} />
					<InputField mb='20px' me='30px' id='phone' label='Teléfono' placeholder='...' value={claim.phone} />

				</SimpleGrid>

				<TextField
					id='message'
					label='Mensaje'
					h='100px'
					placeholder='...'
					value={claim.notes}
				/>
			</Card>
		</FormControl>
	);
}
