import { User } from "firebase/auth";

async function getStaticData(currentUser: User, staticData: string) {

    const currentToken = await currentUser.getIdToken()

    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': process.env.REACT_APP_FOBOS_API_KEY,
            'Authorization': 'Bearer ' + currentToken
        }
    }

    return fetch(process.env.REACT_APP_FOBOS_API_HOST + '/static-data/' + staticData, options)
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            return data;
        });
}

export { getStaticData };
