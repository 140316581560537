import { useContext, useState } from "react";
import { Icon } from "@chakra-ui/icons";
import { useColorModeValue, Link, Button, useDisclosure, Input } from "@chakra-ui/react";
import { BsEye } from "react-icons/bs";
import { TiDelete } from "react-icons/ti";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react'
import { iRoomClaimed } from "types/iRoomClaimed";
import { deleteSingleItem, getSingleItem as getSingleClaim } from "services/claimsService";
import { getSingleItem as getSingleRoom } from "services/roomsService";
import { AuthContext } from "context/auth-context";
import { iRoom } from "types/iRoom";
import RoomProfile from "views/admin/claims/components/RoomProfile";
import ClaimInformation from "views/admin/claims/components/ClaimInformation";

function ClaimRoomsActionsComponent(props: any) {
  const { currentUser } = useContext(AuthContext);

  const { background } = props;
  const inputBg = useColorModeValue('secondaryGray.300', 'fobos.darkGrey');
  const inputText = useColorModeValue('gray.700', 'gray.100');

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenViewDetail,
    onOpen: onOpenViewDetail,
    onClose: onCloseViewDetail
  } = useDisclosure()

  const [deleteIsDisabled, setDeleteIsDisabled] = useState(true)
  const confirmDeletion = (event: any) => {
    if (event.target.value === 'confirmar')
      setDeleteIsDisabled(false)
    else
      setDeleteIsDisabled(true)
  }

  const defaultClaim: iRoomClaimed = {
    id: "",
    name: "",
    created_at: ""
  }
  const defaultRoom: iRoom = {
    id: "",
    name: "",
    image: "",
    is_active: false,
    created_at: ""
  }
  const [claim, setClaim] = useState(defaultClaim);
  const [room, setRoom] = useState(defaultRoom);

  let roomProfileComponent;
  if (room.name !== '') {
    roomProfileComponent = <RoomProfile room={room} />
  } else {
    roomProfileComponent = null
  }
  const loadClaim = (gridId: string, elementId: string) => {
    setRoom(defaultRoom);
    getSingleClaim(currentUser, elementId).then((claim: iRoomClaimed) => {
      setClaim(claim);
      if (claim.hasOwnProperty("room_id")) {
        getSingleRoom(currentUser, claim.room_id).then((room: iRoom) => {
          setRoom(room);
        })
      }
    });
    onOpenViewDetail()
  }

  const deleteClaim = (props: any) => {
    const claimId = props?.node?.data?.id || "";

    if (!deleteIsDisabled) {
      deleteSingleItem(currentUser, claimId).then((deletion: any) => {
        props?.node.setData({});
        onClose();
        onCloseViewDetail();
      })
    }
  };

  const archiveClaim = (props: any) => {
    const claimId = props?.node?.data?.id || "";
    deleteSingleItem(currentUser, claimId).then((deletion: any) => {
      props?.node.setData({});
      onCloseViewDetail();
    })

  };

  return (
    <>
      <Link onClick={() => loadClaim(props.api.getGridId(), props.node.data.id)} title="Ver">
        <Icon
          as={BsEye}
          color={`blue`}
          my='auto'
          w='20px'
          h='20px'
          me='10px'
          _hover={{ cursor: 'pointer' }}
        />
      </Link >
      <Link onClick={onOpen} title="Borrar">
        <Icon
          as={TiDelete}
          color={`red`}
          my='auto'
          w='20px'
          h='20px'
          me='10px'
          _hover={{ cursor: 'pointer' }}
        />
      </Link>

      <Modal isOpen={isOpenViewDetail} onClose={onCloseViewDetail} size='xl'>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Reclamación de empresa</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {roomProfileComponent}
            <ClaimInformation claim={claim} />
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={onCloseViewDetail}>
              Cerrar
            </Button>
            <Button colorScheme='red' mr={3} onClick={() => archiveClaim(props)}>
              Archivar solicitud
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isOpen} onClose={onClose} size='xl'>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>¿Deseas borrar este elemento?</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Estas a punto de borrar este elemento definitivamente del sistema. Por favor escribe <i>confirmar</i> para poder proceder con el borrado
            <Input placeholder='Escribe "confirmar"' bg={background ? background : inputBg} color={inputText} onChange={confirmDeletion} />
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='green' mr={3} onClick={onClose}>
              No
            </Button>
            <Button colorScheme='red' isDisabled={deleteIsDisabled} onClick={() => deleteClaim(props)}>SI</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default ClaimRoomsActionsComponent;