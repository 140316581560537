import { CognitoUserAttribute } from "amazon-cognito-identity-js";
import { iTaxonomy } from "types/iTaxonomy";

export function transformCognitoAttrs(arr: CognitoUserAttribute[]) {
  return arr.reduce((acc: any, curr: CognitoUserAttribute) => {
    acc[curr.Name] = curr.Value;
    return acc;
  }, {});
}

export const sorters = {
  byName: function (a: any, b: any) {
    return ((a.name < b.name) ? -1 : ((a.name > b.name) ? 1 : 0));
  },
  byId: function (a: any, b: any) {
    return ((a.id < b.id) ? -1 : ((a.id > b.id) ? 1 : 0));
  }
}

export function removeNotActive(arr: any[]) {
  const newArr = arr.filter(function (n) {
    return n.is_active;
  });
  return newArr;
}

export function getTaxonomyTermValueById(terms: iTaxonomy[], termId: string) {
  const pos = terms.map(e => e.id).indexOf(termId);
  return terms[pos].name;
}