import React, { useState, useEffect, useContext } from 'react';

// Chakra imports
import { Box } from '@chakra-ui/react';

//Data presentation
import TaxonomiesTable from './components/TaxonomiesTable';
import { getFullList, getSingleElement, getTermsFullList } from 'services/taxonomiesService';
import { AuthContext } from 'context/auth-context';
import { useParams } from 'react-router-dom';
import TaxonomyTermsTable from './components/TaxonomyTermsTable';
import { iTaxonomy } from 'types/iTaxonomy';

export default function Taxonomies() {
	const { currentUser } = useContext(AuthContext);
	const taxonomiesList: iTaxonomy[] = [];
	//State
	const [taxonomies, setTaxonomies]: [iTaxonomy[], (roomList: iTaxonomy[]) => void] = useState(taxonomiesList);

	useEffect(() => {
		getFullList(currentUser).then((taxonomiesList: iTaxonomy[]) => {
			setTaxonomies(taxonomiesList)
		});
	}, []);

	return (
		<Box pt={{ base: '180px', md: '80px', xl: '80px' }}>
			{/* Main Fields */}
			<TaxonomiesTable tableData={taxonomies} />
		</Box>
	);
}

export function TaxonomyTerms() {
	const { currentUser } = useContext(AuthContext);
	const params: any = useParams();
	const { taxonomy_id } = params;
	const taxonomyElement: iTaxonomy = {
		id: '',
		name: '',
		color: '',
		is_active: false,
		created_at: ''
	};
	const taxonomyTermsList: iTaxonomy[] = [];
	//State
	const [taxonomyTerms, setTaxonomyTerms]: [iTaxonomy[], (taxonomyTermList: iTaxonomy[]) => void] = useState(taxonomyTermsList);
	const [taxonomy, setTaxonomy]: [iTaxonomy, (taxonomyElement: iTaxonomy) => void] = useState(taxonomyElement);

	useEffect(() => {
		getTermsFullList(currentUser, taxonomy_id).then((taxonomyTermsList: iTaxonomy[]) => {
			setTaxonomyTerms(taxonomyTermsList)
		});
		getSingleElement(currentUser, taxonomy_id).then((taxonomyElement: iTaxonomy) => {
			setTaxonomy(taxonomyElement)
		})
	}, []);

	return (
		<Box pt={{ base: '180px', md: '80px', xl: '80px' }}>
			{/* Main Fields */}
			<TaxonomyTermsTable tableData={taxonomyTerms} taxonomyData={taxonomy} />
		</Box>
	);
}