// Chakra imports
import { Button, Flex, FormControl, FormLabel, Select, SimpleGrid, Text, useColorModeValue } from '@chakra-ui/react';
import Card from 'components/card/Card';
import { AuthContext } from 'context/auth-context';
import { StaticDataContext } from 'context/static-data-context';
import { sorters } from 'libs/arrayHelper';
import { useContext, useEffect, useState } from 'react';
import { updateSingleItem } from 'services/gamesService';
import { iGame } from 'types/iGame';
import { iTaxonomyTerm } from 'types/iTaxonomyTerm';

export default function Socials(props: {
	game: iGame;
}) {
	const { game } = props;
	const { staticTaxonomyAudiences, staticTaxonomyGameTypes, staticTaxonomyThematic, staticTaxonomyLanguages } = useContext(StaticDataContext);
	const { currentUser } = useContext(AuthContext);
	// Chakra Color Mode
	const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
	const textColorSecondary = 'secondaryGray.600';
	const backgroundColor = useColorModeValue('secondaryGray.200', 'fobos.darkGrey')

	const [web, setWeb] = useState("");
	const [instagram, setInstagram] = useState("");
	const [gameData, setGameData] = useState(game);

	useEffect(() => {
		setGameData(game);
	});

	const updateRoomStatus = (e: any) => {}
	
	const changeHandler = (event: any) => {
		console.log("ChangeHandler")
		let tmpGame = gameData;
		//@ts-ignore
		tmpGame[event.target.id] = event.target.value
		
		setGameData(tmpGame);
		console.log(tmpGame);
		console.log(gameData);
	}
	const saveData = async () => {
		const game: iGame = {
			id: gameData.id,
			//web: web,
			//instagram: instagram
		}
		const savedSocials = {};//await updateSingleItem(room);
		if (savedSocials === 200) {
			alert("Redes actualizadas correctamente")
		} else {
			alert("Ha ocurrido un problema, por favor, vuelve a intentar guardar");
		}
	}

	return (
		<FormControl>
			<Card mb='20px' pb='50px'>
				<Flex direction='column' mb='40px' ms='10px'>
					<Text fontSize='xl' color={textColorPrimary} fontWeight='bold'>
						Tipificación del juego
					</Text>
					<Text fontSize='md' color={textColorSecondary}>
						Completa/Actualiza aquí la tipificación del juego
					</Text>
				</Flex>
				<SimpleGrid columns={{ sm: 1, md: 2 }} spacing={{ base: '20px', xl: '20px' }} marginBottom={5}>
					<FormLabel>Tipo de juego</FormLabel>
					<Select
						id='type_id'
						w='unset'
						variant='transparent'
						display='flex'
						textColor={textColorPrimary}
						color={textColorPrimary}
						backgroundColor={backgroundColor}
						alignItems='center'
						placeholder='Tipo de juego'
						value={gameData.type_id} onChange={(e: any) => {changeHandler(e);}}
					>
						{staticTaxonomyGameTypes.sort(sorters.byName).map((element: iTaxonomyTerm) => (
							<option value={element.id}>{element.name}</option>
						))}
					</Select>
				</SimpleGrid>
				<SimpleGrid columns={{ sm: 1, md: 2 }} spacing={{ base: '20px', xl: '20px' }} marginBottom={5}>
					<FormLabel>Temática</FormLabel>
					<Select
						id='thematic_id'
						w='unset'
						variant='transparent'
						display='flex'
						textColor={textColorPrimary}
						color={textColorPrimary}
						backgroundColor={backgroundColor}
						alignItems='center'
						placeholder='Temática'
						value={gameData.thematic_id} onChange={(e: any) => {changeHandler(e);}}
					>
						{staticTaxonomyThematic.sort(sorters.byName).map((element: iTaxonomyTerm) => (
							<option value={element.id}>{element.name}</option>
						))}
					</Select>
				</SimpleGrid>
				<SimpleGrid columns={{ sm: 1, md: 2 }} spacing={{ base: '20px', xl: '20px' }} marginBottom={5}>
					<FormLabel>Audiencia</FormLabel>
					<Select
						id='audience_id'
						w='unset'
						variant='transparent'
						display='flex'
						textColor={textColorPrimary}
						color={textColorPrimary}
						backgroundColor={backgroundColor}
						alignItems='center'
						placeholder='Audiencia'
						value={gameData.audience_id} onChange={(e: any) => {changeHandler(e);}}
					>
						{staticTaxonomyAudiences.sort(sorters.byName).map((element: iTaxonomyTerm) => (
							<option value={element.id}>{element.name}</option>
						))}
					</Select>
				</SimpleGrid>
				<SimpleGrid columns={{ sm: 1, md: 2 }} spacing={{ base: '20px', xl: '20px' }} marginBottom={5}>
				<FormLabel>Idiomas</FormLabel>
					<Select
						id='languages_id'
						w='unset'
						variant='transparent'
						multiple={true}
						display='flex'
						textColor={textColorPrimary}
						color={textColorPrimary}
						backgroundColor={backgroundColor}
						alignItems='center'
						placeholder='Idiomas'
						minH={56}
						value={gameData.languages_id} onChange={(e: any) => {changeHandler(e);}}
					>
						{staticTaxonomyLanguages.sort(sorters.byName).map((element: iTaxonomyTerm) => (
							<option value={element.id}>{element.name}</option>
						))}
					</Select>
				</SimpleGrid>
				
				<Button variant='darkBrand' minW='183px' fontSize='sm' fontWeight='500' ms='auto' onClick={saveData}>
					Guardar
				</Button>
			</Card>
		</FormControl>
	);
}
