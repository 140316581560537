import { Flex, Box, Text, useColorModeValue } from '@chakra-ui/react';

// AG Grid
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import ImageComponent from 'components/ag-tables/ImageComponent';
import ClaimRoomsActionsComponent from 'components/ag-tables/ClaimRoomActionsComponent';

// Custom components
import Card from 'components/card/Card';
import CheckboxComponent from 'components/ag-tables/CheckBoxComponent';
//import CheckBoxRenderer from 'components/ag-tables/CheckBoxRenderer';

// const columns = columnsDataCheck;
export default function RoomsClaimTable(props: { tableData: any }) {
	const { tableData } = props;
	const textColor = useColorModeValue('secondaryGray.900', 'white');

	//AG GRID Table definition
	const roomsColumnDefs = [
		{ field: 'name', headerName: 'Nombre' },
		{ field: 'email', headerName: 'Email' },
		{ field: 'phone', headerName: 'Teléfono', width: 150 },
		{ field: 'room_name', headerName: 'Empresa', width: 250 },
		{ field: 'created_at', headerName: "F. Solicitud", witdh: 150 },
		{ field: 'is_verified', headerName: "Verificado?", witdh: 150, cellRenderer: CheckboxComponent },
		{ field: 'actions', headerName: 'Acciones', sortable: false, filter: false, unSortIcon: false, floatingFilter: false, cellRenderer: ClaimRoomsActionsComponent },
	];

	const defaultColDef = {
		flex: 1,
		sortable: true,
		filter: true,
		unSortIcon: true,
		floatingFilter: true,
		resizable: true
	};
	//End of AG Table Definition

	return (
		<Card flexDirection='column' w='100%' px='0px' overflowX={{ sm: 'scroll', lg: 'hidden' }}>
			<Flex px='25px' mb="8px" justifyContent='space-between' align='center'>
				<Text color={textColor} fontSize='22px' mb="4px" fontWeight='700' lineHeight='100%'>
					Listado de empresas reclamadas
				</Text>

			</Flex>
			<Box>
				<div id="rooms-ag-grid" className='hero ag-theme-alpine' style={{ height: 700 }}>
					<AgGridReact
						gridId='claims'
						rowData={tableData}
						columnDefs={roomsColumnDefs}
						defaultColDef={defaultColDef}
						animateRows={true}
						overlayNoRowsTemplate='<span style="padding: 10px; border: 2px solid #444;">Cargando información...</span>'
						onFilterChanged={(event: any) => {
							let filterModel = event.api.getFilterModel();
							localStorage.setItem('roomsClaimFilterModel', JSON.stringify(filterModel));
						}}
						onFirstDataRendered={(params: any) => {
							const filterModel = JSON.parse(localStorage.getItem('roomsClaimFilterModel') || '{}');
							params.api.setFilterModel(filterModel);
							// Hide columns in mobile view
							if (window.innerWidth < 786) {
								params.columnApi.setColumnVisible("description", false);
								params.columnApi.setColumnVisible("email", false);
							}
						}}
						components={{ ImageComponent, ClaimRoomsActionsComponent, CheckboxComponent }}
					/>
				</div>
			</Box>

		</Card>
	);
} 