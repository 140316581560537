// Chakra imports
import {
	Box,
	Button,
	Flex,
	FormLabel,
	Select,
	SimpleGrid,
	Icon,
	Stack,
	Tab,
	TabList,
	TabPanel,
	TabPanels,
	Tabs,
	Text,
	useColorModeValue,
	SelectField
} from '@chakra-ui/react';

// Custom components
import Card from 'components/card/Card';
import InputField from 'components/fields/InputField';
import TextField from 'components/fields/TextField';
import TagsField from 'components/fields/TagsField';
import Dropzone from './components/Dropzone';
import React, { useContext, useEffect, useState } from 'react';

// Assets
import { MdOutlineCloudUpload } from 'react-icons/md';
import banner from 'assets/img/backgrounds/banner.png';
import { StaticDataContext } from 'context/static-data-context';
import { iRoom } from 'types/iRoom';
import { sorters } from 'libs/arrayHelper';
import { iTaxonomyTerm } from 'types/iTaxonomyTerm';
import { iGame } from 'types/iGame';
import { createItem } from 'services/gamesService';
import { getCurrentUser } from 'libs/cognito';
import { AuthContext } from 'context/auth-context';

export default function NewGame() {
	const { currentUser } = useContext(AuthContext);

	const textColor = useColorModeValue('secondaryGray.900', 'white');
	const [activeBullets, setActiveBullets] = useState({
		roomDetails: true,
		logo: false,
		social: false,
		geo: false,
		resume: false
	});

	const initialGame: iGame = {};
	const [game, setGame]: [iGame, (game: iGame) => void] = useState(initialGame);

	// Game fields, using states for input binding
	const [name, setName] = useState('');
	const [description, setDescription] = useState('');
	const [room_id, setRoom_id] = useState('');
	const [room_name, setRoom_name] = useState('');
	const [image, setImage] = useState('');
	const [audience_id, setAudience_id] = useState('');
	const [audience_name, setAudience_name] = useState('');
	const [entity, setEntity] = useState('game');
	const [is_validated, setIs_validated] = useState(true);
	const [languages_id, setLanguages_id] = useState([]);
	const [languages_name, setLanguages_name] = useState([]);
	const [max_players, setMax_players] = useState();
	const [max_price, setMax_price] = useState();
	const [min_players, setMin_players] = useState();
	const [min_price, setMin_price] = useState();
	const [rating, setRating] = useState(0);
	const [request_validation, setRequest_validation] = useState(false);
	const [status_id, setStatus_id] = useState('');
	const [status_name, setStatus_name] = useState('');
	const [thematic_id, setThematic_id] = useState('');
	const [thematic_name, setThematic_name] = useState('');
	const [time, setTime] = useState();
	const [treasures, setTreasures] = useState();
	const [type_id, setType_id] = useState('');
	const [type_name, setType] = useState('');
	const [video, setVideo] = useState('');
	const [status, setStatus] = useState('');
	const [is_active, setIs_active] = useState(true);
	const [year, setYear] = useState();
	const [faqs, setFaqs] = useState('');
	//End of game Fields

	const { staticRooms, staticTaxonomyGameTypes, staticTaxonomyThematic, staticTaxonomyRoomStatuses, staticTaxonomyCountries, staticTaxonomyProvinces } = useContext(StaticDataContext);

	const roomDetailTab = React.useRef() as React.MutableRefObject<HTMLInputElement>;
	const logoTab = React.useRef() as React.MutableRefObject<HTMLInputElement>;
	const gameDetailTab = React.useRef() as React.MutableRefObject<HTMLInputElement>;
	const othersTab = React.useRef() as React.MutableRefObject<HTMLInputElement>;
	const resumeTab = React.useRef() as React.MutableRefObject<HTMLInputElement>;
	const brand = useColorModeValue('brand.500', 'brand.400');

	const initYear = 2015;
	const years = Array.from(new Array(10), (val, index) => index + initYear);

	const preSaveGame = () => {
		let currentGame: iGame = game;
		currentGame.name = name;
		currentGame.description = description;
		currentGame.room_id = room_id;
		if (room_id && room_id !== '') {
			const pos = staticRooms.map(e => e.id).indexOf(room_id);
			setRoom_name(staticRooms[pos].name);
			currentGame.room_name = staticRooms[pos].name;
		}

		currentGame.image = image;
		currentGame.audience_id = audience_id;
		//currentGame.audience_name = audience_name;
		currentGame.entity = entity;
		currentGame.is_validated = is_validated;
		currentGame.languages_id = languages_id;
		//currentGame.languages_name = languages_name;
		currentGame.max_players = max_players;
		currentGame.max_price = max_price;
		currentGame.min_players = min_players;
		currentGame.min_price = min_price;
		currentGame.rating = rating;
		currentGame.request_validation = request_validation;
		currentGame.status_id = status_id;
		//currentGame.status_name = status_name;
		currentGame.thematic_id = thematic_id;
		//currentGame.thematic_name = thematic_name;
		currentGame.time = time;
		currentGame.treasures = treasures;
		currentGame.type_id = type_id;
		//currentGame.type_name = type_name;
		currentGame.video = video;
		currentGame.status = status;
		currentGame.is_active = is_active;
		currentGame.year = year;
		setGame(currentGame);
	}

	const createGame = () => {
		const createdGame = createItem(currentUser, game);
	}

	useEffect(() => {

	});

	return (
		<Flex direction='column' minH='100vh' align='center' pt={{ sm: '125px', lg: '75px' }} position='relative'>
			<Box
				h='45vh'
				bg='linear-gradient(135deg, #868CFF 0%, #4318FF 100%)'
				bgImage={banner}
				position='absolute'
				w='100%'
				borderRadius='30px'
			/>
			<Tabs
				variant='unstyled'
				mt={{ base: '60px', md: '165px' }}
				zIndex='0'
				display='flex'
				flexDirection='column'>
				<TabList display='flex' alignItems='center' alignSelf='center' justifySelf='center'>
					<Tab
						ref={roomDetailTab}
						w={{ sm: '60px', md: '125px', lg: '150px' }}
						onClick={() =>
							setActiveBullets({
								roomDetails: true,
								logo: false,
								social: false,
								geo: false,
								resume: false
							})}>
						<Flex
							direction='column'
							justify='center'
							align='center'
							position='relative'
							_before={{
								content: "''",
								width: { sm: '120px', md: '250px', lg: '150px' },
								height: '3px',
								bg: activeBullets.logo ? 'white' : '#8476FF',
								left: { sm: '10px', md: '20px' },
								top: {
									sm: activeBullets.roomDetails ? '6px' : '4px',
									md: null
								},
								position: 'absolute',
								bottom: activeBullets.roomDetails ? '40px' : '38px',

								transition: 'all .3s ease'
							}}>
							<Box
								zIndex='1'
								border='2px solid'
								borderColor={activeBullets.roomDetails ? 'white' : '#8476FF'}
								bg='linear-gradient(135deg, #868CFF 0%, #4318FF 100%)'
								w='16px'
								h='16px'
								mb='8px'
								borderRadius='50%'
							/>
							<Text
								color={activeBullets.roomDetails ? 'white' : 'gray.300'}
								fontWeight={activeBullets.roomDetails ? 'bold' : 'normal'}
								display={{ sm: 'none', md: 'block' }}>
								Datos
							</Text>
						</Flex>
					</Tab>
					<Tab
						ref={logoTab}
						w={{ sm: '60px', md: '125px', lg: '150px' }}
						onClick={() =>
							setActiveBullets({
								roomDetails: true,
								logo: true,
								social: false,
								geo: false,
								resume: false
							})}>
						<Flex
							direction='column'
							justify='center'
							align='center'
							position='relative'
							_before={{
								content: "''",
								width: { sm: '120px', md: '250px', lg: '150px' },
								height: '3px',
								bg: activeBullets.social ? 'white' : '#8476FF',
								left: { sm: '12px', md: '20px' },
								top: '6px',
								position: 'absolute',
								bottom: activeBullets.logo ? '40px' : '38px',
								transition: 'all .3s ease'
							}}>
							<Box
								zIndex='1'
								border='2px solid'
								borderColor={activeBullets.logo ? 'white' : '#8476FF'}
								bg='linear-gradient(135deg, #868CFF 0%, #4318FF 100%)'
								w='16px'
								h='16px'
								mb='8px'
								borderRadius='50%'
							/>
							<Text
								color={activeBullets.logo ? 'white' : 'gray.300'}
								fontWeight={activeBullets.logo ? 'bold' : 'normal'}
								display={{ sm: 'none', md: 'block' }}>
								Logo
							</Text>
						</Flex>
					</Tab>
					<Tab
						ref={gameDetailTab}
						w={{ sm: '60px', md: '125px', lg: '150px' }}
						onClick={() =>
							setActiveBullets({
								roomDetails: true,
								logo: true,
								social: true,
								geo: false,
								resume: false
							})}>
						<Flex
							direction='column'
							justify='center'
							align='center'
							position='relative'
							_before={{
								content: "''",
								width: { sm: '120px', md: '250px', lg: '150px' },
								height: '3px',
								bg: activeBullets.geo ? 'white' : '#8476FF',
								left: { sm: '12px', md: '18px' },
								top: '6px',
								position: 'absolute',
								bottom: activeBullets.social ? '40px' : '38px',
								transition: 'all .3s ease'
							}}>
							<Box
								zIndex='1'
								border='2px solid'
								borderColor={activeBullets.social ? 'white' : '#8476FF'}
								bg='linear-gradient(135deg, #868CFF 0%, #4318FF 100%)'
								w='16px'
								h='16px'
								mb='8px'
								borderRadius='50%'
							/>
							<Text
								color={activeBullets.social ? 'white' : 'gray.300'}
								fontWeight={activeBullets.social ? 'bold' : 'normal'}
								display={{ sm: 'none', md: 'block' }}>
								Info
							</Text>
						</Flex>
					</Tab>
					<Tab
						ref={othersTab}
						w={{ sm: '60px', md: '125px', lg: '150px' }}
						onClick={() =>
							setActiveBullets({
								roomDetails: true,
								logo: true,
								social: true,
								geo: true,
								resume: false
							})}>
						<Flex
							direction='column'
							justify='center'
							align='center'
							position='relative'
							_before={{
								content: "''",
								width: { sm: '120px', md: '250px', lg: '150px' },
								height: '3px',
								bg: activeBullets.resume ? 'white' : '#8476FF',
								left: { sm: '10px', md: '20px' },
								top: '6px',
								position: 'absolute',
								bottom: activeBullets.geo ? '40px' : '38px',
								transition: 'all .3s ease'
							}}>
							<Box
								zIndex='1'
								border='2px solid'
								borderColor={activeBullets.geo ? 'white' : '#8476FF'}
								bg='linear-gradient(135deg, #868CFF 0%, #4318FF 100%)'
								w='16px'
								h='16px'
								mb='8px'
								borderRadius='50%'
							/>
							<Text
								color={activeBullets.geo ? 'white' : 'gray.300'}
								fontWeight={activeBullets.geo ? 'bold' : 'normal'}
								display={{ sm: 'none', md: 'block' }}>
								Otros
							</Text>
						</Flex>
					</Tab>
					<Tab
						ref={resumeTab}
						w={{ sm: '60px', md: '125px', lg: '150px' }}
						onClick={() =>
							setActiveBullets({
								roomDetails: true,
								logo: true,
								social: true,
								geo: true,
								resume: true
							})}>
						<Flex direction='column' justify='center' align='center' position='relative'>
							<Box
								zIndex='1'
								border='2px solid'
								borderColor={activeBullets.resume ? 'white' : '#8476FF'}
								bg='linear-gradient(135deg, #868CFF 0%, #4318FF 100%)'
								w='16px'
								h='16px'
								mb='8px'
								borderRadius='50%'
							/>
							<Text
								color={activeBullets.resume ? 'white' : 'gray.300'}
								fontWeight={activeBullets.resume ? 'bold' : 'normal'}
								display={{ sm: 'none', md: 'block' }}>
								Resumen
							</Text>
						</Flex>
					</Tab>
				</TabList>
				<TabPanels mt='24px' maxW={{ md: '90%', lg: '100%' }} mx='auto'>
					<TabPanel w={{ sm: '330px', md: '700px', lg: '850px' }} p='0px' mx='auto'>
						<Card p='30px'>
							<Text color={textColor} fontSize='2xl' fontWeight='700' mb='20px'>
								Nuevo juego
							</Text>
							<Flex direction='column' w='100%'>
								<SimpleGrid columns={{ base: 1, md: 2 }} gap='20px'>
									<Stack direction='column' gap='20px'>
										<Select
											mb='0px'
											id='room_id'
											value={room_id}
											onChange={(e: any) => setRoom_id(e.target.value)}
											placeholder='Seleciona empresa'
										>
											{staticRooms.sort(sorters.byName).map((room: iRoom) => (
												<option value={room.id}>{room.name}</option>
											))}
										</Select>
										<InputField mb='0px' id='name' value={name} onChange={(e: any) => setName(e.target.value)} placeholder='ej: La oscura historia de Fobos' label='Nombre' />
										<InputField mb='20px' id='video' value={video} onChange={(e: any) => setVideo(e.target.value)} placeholder='ej: https://youtube.com/1234' label='Video promocional' />

									</Stack>
									<Stack direction='column' gap='20px'>
										<Select
											mb='30px'
											id='opening_year'
											placeholder='Año apertura'
											value={year} onChange={(e: any) => setYear(e.target.value)}
										>
											{years.map((year: number) => (
												<option value={year}>{year}</option>
											))}
										</Select>
										<Select
											mb='0px'
											id='status'
											placeholder='Estado'
											value={status_id} onChange={(e: any) => setStatus_id(e.target.value)}
										>
											{staticTaxonomyRoomStatuses.sort(sorters.byName).map((element: iTaxonomyTerm) => (
												<option value={element.id}>{element.name}</option>
											))}
										</Select>


									</Stack>
								</SimpleGrid>
								<SimpleGrid gap='20px' mt="20px">
									<Stack direction='column' gap='20px'>
										<TextField
											h='146px'
											mb='0px'
											id='description'
											placeholder='ej: detalles del juego'
											label='Descripción'
											value={description} onChange={(e: any) => setDescription(e.target.value)}
										/>
									</Stack>
								</SimpleGrid>
								<Flex justify='space-between' mt='24px'>
									<Button
										variant='darkBrand'
										fontSize='sm'
										borderRadius='16px'
										w={{ base: '128px', md: '148px' }}
										h='46px'
										ms='auto'
										onClick={() => { preSaveGame(); logoTab.current.click() }}>
										Siguiente
									</Button>
								</Flex>
							</Flex>
						</Card>
					</TabPanel>
					<TabPanel w={{ sm: '330px', md: '700px', lg: '850px' }} p='0px' mx='auto'>
						<Card p='30px'>
							<Text color={textColor} fontSize='2xl' fontWeight='700' mb='20px'>
								Logo
							</Text>
							<Dropzone
								content={
									<Box>
										<Icon as={MdOutlineCloudUpload} w='80px' h='80px' color={textColor} />
										<Text
											mx='auto'
											mb='12px'
											fontSize='lg'
											fontWeight='700'
											whiteSpace='pre-wrap'
											color={textColor}>
											Drop your files here, or{' '}
											<Text as='span' fontSize='lg' fontWeight='700' color={brand}>
												browse
											</Text>
										</Text>
										<Text fontSize='sm' fontWeight='500' color='secondaryGray.500'>
											PNG, JPG and GIF files are allowed
										</Text>
									</Box>
								}
							/>
							<Flex justify='space-between' mt='24px'>
								<Button
									variant='light'
									fontSize='sm'
									borderRadius='16px'
									w={{ base: '128px', md: '148px' }}
									h='46px'
									onClick={() => roomDetailTab.current.click()}>
									Anterior
								</Button>
								<Button
									variant='darkBrand'
									fontSize='sm'
									borderRadius='16px'
									w={{ base: '128px', md: '148px' }}
									h='46px'
									onClick={() => gameDetailTab.current.click()}>
									Siguiente
								</Button>
							</Flex>
						</Card>
					</TabPanel>
					<TabPanel w={{ sm: '330px', md: '700px', lg: '850px' }} p='0px' mx='auto'>
						<Card p='30px'>
							<Text color={textColor} fontSize='2xl' fontWeight='700' mb='20px'>
								Información de partida
							</Text>
							<Flex direction='column' w='100%'>
								<Stack direction='column' spacing='20px'>
									<SimpleGrid columns={{ base: 1, md: 2 }} gap={{ base: '0px', md: '20px' }}>
										<InputField id='price_min' placeholder='fobosescape' label='Precio mínimo' value={min_price} onChange={(e: any) => setMin_price(e.target.value)} />
										<InputField id='price_max' placeholder='ej: https://fobosescape.com' label='Precio máximo' value={max_price} onChange={(e: any) => setMax_price(e.target.value)} />
										<InputField id='players_min' placeholder='fobosescape' label='Jugadores (mínimo)' value={min_players} onChange={(e: any) => setMin_players(e.target.value)} />
										<InputField id='players_max' placeholder='fobosescape' label='Jugadores (máximo)' value={max_players} onChange={(e: any) => setMax_players(e.target.value)} />
										<InputField id='type' placeholder='fobosescape' label='Tipo' value={type_id} onChange={(e: any) => setType_id(e.target.value)} />
										<InputField id='thematic' placeholder='fobosescape' label='Temática' value={thematic_id} onChange={(e: any) => setThematic_id(e.target.value)} />
										<InputField id='audience' placeholder='fobosescape' label='Audiencia' value={audience_id} onChange={(e: any) => setAudience_id(e.target.value)} />
										<InputField id='time' placeholder='fobosescape' label='Tiempo Partida' value={time} onChange={(e: any) => setTime(e.target.value)} />
										<InputField id='languages' placeholder='fobosescape' label='Idiomas' value={languages_id} onChange={(e: any) => setLanguages_id(e.target.value)} />
									</SimpleGrid>
								</Stack>
								<Flex justify='space-between' mt='24px'>
									<Button
										variant='light'
										fontSize='sm'
										borderRadius='16px'
										w={{ base: '128px', md: '148px' }}
										h='46px'
										onClick={() => { preSaveGame(); logoTab.current.click() }}>
										Anterior
									</Button>
									<Button
										variant='darkBrand'
										fontSize='sm'
										borderRadius='16px'
										w={{ base: '128px', md: '148px' }}
										onClick={() => { preSaveGame(); othersTab.current.click() }}
										h='46px'>
										Siguiente
									</Button>
								</Flex>
							</Flex>
						</Card>
					</TabPanel>
					<TabPanel w={{ sm: '330px', md: '700px', lg: '850px' }} p='0px' mx='auto'>
						<Card p='30px'>
							<Text color={textColor} fontSize='2xl' fontWeight='700' mb='20px'>
								Otros
							</Text>
							<Flex direction='column' w='100%'>
								<Stack direction='column' spacing='20px'>
									<SimpleGrid columns={{ base: 1, md: 2 }} gap={{ base: '0px', md: '20px' }}>
										<InputField id='faqs' placeholder='ej: 13.1896' label='FAQs' value={faqs} onChange={(e: any) => setFaqs(e.target.value)} />
									</SimpleGrid>
								</Stack>
								<Flex justify='space-between' mt='24px'>
									<Button
										variant='light'
										fontSize='sm'
										borderRadius='16px'
										w={{ base: '128px', md: '148px' }}
										h='46px'
										onClick={() => { preSaveGame(); gameDetailTab.current.click() }}>
										Anterior
									</Button>
									<Button
										variant='darkBrand'
										fontSize='sm'
										borderRadius='16px'
										w={{ base: '128px', md: '148px' }}
										onClick={() => { preSaveGame(); resumeTab.current.click() }}
										h='46px'>
										Siguiente
									</Button>
								</Flex>
							</Flex>
						</Card>
					</TabPanel>
					<TabPanel w={{ sm: '330px', md: '700px', lg: '850px' }} p='0px' mx='auto'>
						<Card p='30px'>
							<Text color={textColor} fontSize='2xl' fontWeight='700' mb='20px'>
								Resumen
							</Text>
							<Text color={textColor} fontSize='lg' fontWeight='700' mb='20px'>
								Nuevo juego en '{room_name}''
							</Text>
							<Flex direction='column' w='100%'>
								<Stack direction='column' spacing='20px'>
									<SimpleGrid columns={{ base: 1, md: 2 }} gap={{ base: '0px', md: '20px' }}>
										El resumen del juego va aquí
									</SimpleGrid>
								</Stack>
								<Flex justify='space-between' mt='24px'>
									<Button
										variant='light'
										fontSize='sm'
										borderRadius='16px'
										w={{ base: '128px', md: '148px' }}
										h='46px'
										onClick={() => { preSaveGame(); othersTab.current.click() }}>
										Anterior
									</Button>
									<Button
										variant='darkBrand'
										fontSize='sm'
										borderRadius='16px'
										w={{ base: '128px', md: '148px' }}
										h='46px'
										onClick={createGame}>
										Dar de alta
									</Button>
								</Flex>
							</Flex>
						</Card>
					</TabPanel>
				</TabPanels>
			</Tabs>
		</Flex >
	);
}
