import { createContext, useState, useEffect, ReactNode, useContext } from "react";
import { getStaticData } from "services/staticDataService";
import { AuthContext } from "./auth-context";
import { iGame } from "types/iGame";
import { iUser } from "types/iUser";
import { iTaxonomyTerm } from "types/iTaxonomyTerm";
import { iRoom } from "types/iRoom";

interface Props {
    children?: ReactNode
}

export const StaticDataContext = createContext({
    // "User" comes from firebase auth-public.d.ts
    staticRooms: [],
    staticGames: [],
    staticUsers: [],
    staticTeams: [],
    staticTaxonomyAudiences: [],
    staticTaxonomyCities: [],
    staticTaxonomyRoomStatuses: [],
    staticTaxonomyLanguages: [],
    staticTaxonomyCountries: [],
    staticTaxonomyProvinces: [],
    staticTaxonomyThematic: [],
    staticTaxonomyGameTypes: [],
});


export const StaticDataProvider = ({ children }: Props) => {
    const { currentUser } = useContext(AuthContext);

    const [staticRooms, setStaticRooms] = useState<iRoom[] | null>(null);
    const [staticGames, setStaticGames] = useState<iGame[] | null>(null);
    const [staticUsers, setStaticUsers] = useState<iUser[] | null>(null);
    const [staticTeams, setStaticTeams] = useState<any[] | null>(null);
    const [staticTaxonomyAudiences, setStaticTaxonomyAudiences] = useState<iTaxonomyTerm[] | null>(null);
    const [staticTaxonomyCities, setStaticTaxonomyCities] = useState<iTaxonomyTerm[] | null>(null);
    const [staticTaxonomyRoomStatuses, setStaticTaxonomyRoomStatuses] = useState<iTaxonomyTerm[] | null>(null);
    const [staticTaxonomyLanguages, setStaticTaxonomyLanguages] = useState<iTaxonomyTerm[] | null>(null);
    const [staticTaxonomyCountries, setStaticTaxonomyCountries] = useState<iTaxonomyTerm[] | null>(null);
    const [staticTaxonomyProvinces, setStaticTaxonomyProvinces] = useState<iTaxonomyTerm[] | null>(null);
    const [staticTaxonomyThematic, setStaticTaxonomyThematic] = useState<iTaxonomyTerm[] | null>(null);
    const [staticTaxonomyGameTypes, setStaticTaxonomyGameTypes] = useState<iTaxonomyTerm[] | null>(null);

    useEffect(() => {
        getStaticData(currentUser, "rooms").then((staticDataList: iRoom[]) => {
            setStaticRooms(staticDataList);
        });
        getStaticData(currentUser, "games").then((staticDataList: iGame[]) => {
            setStaticGames(staticDataList);
        });
        getStaticData(currentUser, "users").then((staticDataList: iUser[]) => {
            setStaticUsers(staticDataList);
        });
        getStaticData(currentUser, "teams").then((staticDataList: any[]) => {
            setStaticTeams(staticDataList);
        });
        getStaticData(currentUser, "taxonomies-audiencia").then((staticDataList: iTaxonomyTerm[]) => {
            setStaticTaxonomyAudiences(staticDataList);
        });
        getStaticData(currentUser, "taxonomies-ciudad").then((staticDataList: iTaxonomyTerm[]) => {
            setStaticTaxonomyCities(staticDataList);
        });
        getStaticData(currentUser, "taxonomies-estado-salas").then((staticDataList: iTaxonomyTerm[]) => {
            setStaticTaxonomyRoomStatuses(staticDataList);
        });
        getStaticData(currentUser, "taxonomies-idioma").then((staticDataList: iTaxonomyTerm[]) => {
            setStaticTaxonomyLanguages(staticDataList);
        });
        getStaticData(currentUser, "taxonomies-pais").then((staticDataList: iTaxonomyTerm[]) => {
            setStaticTaxonomyCountries(staticDataList);
        });
        getStaticData(currentUser, "taxonomies-provincias").then((staticDataList: iTaxonomyTerm[]) => {
            setStaticTaxonomyProvinces(staticDataList);
        });
        getStaticData(currentUser, "taxonomies-tematica").then((staticDataList: iTaxonomyTerm[]) => {
            setStaticTaxonomyThematic(staticDataList);
        });
        getStaticData(currentUser, "taxonomies-tipos-de-juego").then((staticDataList: iTaxonomyTerm[]) => {
            setStaticTaxonomyGameTypes(staticDataList);
        });
    }, [currentUser]);

    const value = {
        staticRooms,
        staticGames,
        staticUsers,
        staticTeams,
        staticTaxonomyAudiences,
        staticTaxonomyCities,
        staticTaxonomyRoomStatuses,
        staticTaxonomyLanguages,
        staticTaxonomyCountries,
        staticTaxonomyProvinces,
        staticTaxonomyThematic,
        staticTaxonomyGameTypes
    }

    return <StaticDataContext.Provider value={value}>{children}</StaticDataContext.Provider>;
}