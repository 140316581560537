import { User } from "firebase/auth";
import { iMetric } from "types/iMetric";

async function getSingleItem(currentUser: User, metricId: string): Promise<iMetric> {

    const currentToken = await currentUser.getIdToken()

    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': process.env.REACT_APP_FOBOS_API_KEY,
            'Authorization': 'Bearer ' + currentToken
        }
    }

    return fetch(process.env.REACT_APP_FOBOS_API_HOST + '/metrics/' + metricId, options)
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            let metrics = {};
            for (let metric of data.data) {
                metrics = Object.assign(metrics, { [metric.rangeKey]: metric.metric_value });
            }
            return metrics;
        });
}

export { getSingleItem }