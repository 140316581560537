// Import Icons
import { Icon } from '@chakra-ui/react';
import { MdHomeWork, MdHome, MdOutlineRoomPreferences, MdOutlineVerified, MdCategory, MdLock } from 'react-icons/md';
import { GiPuzzle } from "react-icons/gi";
import { HiOutlineUsers } from "react-icons/hi";

// Admin Imports
import MainDashboard from 'views/admin/default';
import Rooms from 'views/admin/rooms';
import Profile from 'views/admin/profile';
import RoomsClaim from 'views/admin/claims';
import Games from 'views/admin/games';
import Taxonomies, { TaxonomyTerms } from 'views/admin/taxonomies';
import Users from 'views/admin/users';
import Ratings from 'views/admin/ratings';
import RoomDetail from 'views/admin/rooms/detail';
import SignIn from 'views/auth/signIn';
import { IoAnalyticsOutline } from 'react-icons/io5';
import NewRoom from 'views/admin/rooms/create';
import NewGame from 'views/admin/games/create';
import NewTaxonomy from 'views/admin/taxonomies/create';
import GameDetails from 'views/admin/games/detail';

const routes = [
	{
		name: 'Inicio',
		layout: '/admin',
		path: '/default',
		icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
		component: MainDashboard
	},
	{
		name: 'Empresas',
		layout: '/admin',
		path: '/rooms',
		icon: <Icon as={MdHomeWork} width='20px' height='20px' color='inherit' />,
		component: Rooms,
		secondary: true,
	},
	{
		name: 'Juegos',
		layout: '/admin',
		path: '/games',
		icon: <Icon as={GiPuzzle} width='20px' height='20px' color='inherit' />,
		component: Games,
		secondary: true
	},
	{
		name: 'Reclamación Empresas',
		layout: '/admin',
		path: '/claims',
		icon: <Icon as={MdOutlineRoomPreferences} width='20px' height='20px' color='inherit' />,
		component: RoomsClaim,
		secondary: true
	},
	{
		name: 'Puntuaciones & Partidas',
		layout: '/admin',
		path: '/ratings',
		icon: <Icon as={IoAnalyticsOutline} width='20px' height='20px' color='inherit' />,
		component: Ratings,
		secondary: true
	},

	{
		name: 'Taxonomías',
		layout: '/admin',
		icon: <Icon as={MdCategory} width='20px' height='20px' color='inherit' />,
		path: '/taxonomies',
		component: Taxonomies,
		secondary: true
	},
	{
		name: 'Usuarios',
		layout: '/admin',
		path: '/users',
		icon: <Icon as={HiOutlineUsers} width='20px' height='20px' color='inherit' />,
		component: Users,
		secondary: true
	},
];

export default routes;


const deepRoutes = [
	{
		name: 'Sign In',
		layout: '/auth',
		path: '/sign-in',
		icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
		component: SignIn
	},
	{
		name: 'Crear empresa',
		layout: '/admin',
		path: '/rooms/create',
		icon: <Icon as={MdCategory} width='20px' height='20px' color='inherit' />,
		component: NewRoom,
		secondary: true
	},
	{
		name: 'Crear juego',
		layout: '/admin',
		path: '/games/create',
		icon: <Icon as={MdCategory} width='20px' height='20px' color='inherit' />,
		component: NewGame,
		secondary: true
	},
	{
		name: 'Crear taxonomía',
		layout: '/admin',
		path: '/taxonomies/create',
		icon: <Icon as={MdCategory} width='20px' height='20px' color='inherit' />,
		component: NewTaxonomy,
		secondary: true
	},
	{
		name: 'Crear término',
		layout: '/admin',
		path: '/taxonomies/:taxonomy_id/create',
		icon: <Icon as={MdCategory} width='20px' height='20px' color='inherit' />,
		component: NewRoom,
		secondary: true
	},
	{
		name: 'Vista de empresa',
		layout: '/admin',
		path: '/rooms/:room_id',
		icon: <Icon as={MdCategory} width='20px' height='20px' color='inherit' />,
		component: RoomDetail,
		secondary: true
	},
	{
		name: 'Vista de Juego',
		layout: '/admin',
		path: '/games/:game_id',
		icon: <Icon as={MdCategory} width='20px' height='20px' color='inherit' />,
		component: GameDetails,
		secondary: true
	},
	{
		name: 'Vista de usuario',
		layout: '/admin',
		path: '/users/:user_id',
		icon: <Icon as={MdCategory} width='20px' height='20px' color='inherit' />,
		component: Profile,
		secondary: true
	},
	{
		name: 'Términos de taxonomía',
		layout: '/admin',
		path: '/taxonomies/:taxonomy_id',
		icon: <Icon as={MdCategory} width='20px' height='20px' color='inherit' />,
		component: TaxonomyTerms,
		secondary: true
	},
];

export { deepRoutes };