import { Flex, Box, Text, useColorModeValue } from '@chakra-ui/react';

// AG Grid
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import PlayedGameImageComponent from 'components/ag-tables/PlayedGameImageComponent';
import ActionsComponent from 'components/ag-tables/ActionsComponent';
import CustomLoadingCellRenderer from 'components/ag-tables/LoadingComponent';

// Custom components
import Card from 'components/card/Card';

// const columns = columnsDataCheck;
export default function RatingsTable(props: { tableData: any }) {
	const { tableData } = props;
	const textColor = useColorModeValue('secondaryGray.900', 'white');

	//AG GRID Table definition
	const roomsColumnDefs = [
		{ field: 'image', headerName: 'Foto', sortable: false, unSortIcon: false, floatingFilter: false, maxWidth: 100, cellRenderer: PlayedGameImageComponent },
		{ field: 'room_name', headerName: 'Empresa' },
		{ field: 'game_name', headerName: 'Juego' },
		{ field: 'user_nickname', headerName: 'Usuario' },
		{ field: 'team_name', headerName: 'Equipo' },
		{ field: 'average', headerName: 'Nota Media', width: 75 },
		{ field: 'played_at', headerName: 'F. Partida', width: 75 },
		{ field: 'actions', headerName: 'Acciones', sortable: false, filter: false, unSortIcon: false, floatingFilter: false, cellRenderer: ActionsComponent },
	];

	const defaultColDef = {
		flex: 1,
		sortable: true,
		filter: true,
		unSortIcon: true,
		floatingFilter: true,
		resizable: true
	};
	//End of AG Table Definition

	return (
		<Card flexDirection='column' w='100%' px='0px' overflowX={{ sm: 'scroll', lg: 'hidden' }}>
			<Flex px='25px' mb="8px" justifyContent='space-between' align='center'>
				<Text color={textColor} fontSize='22px' mb="4px" fontWeight='700' lineHeight='100%'>
					Listado de valoraciones
				</Text>

			</Flex>
			<Box>
				<div id="rooms-ag-grid" className='hero ag-theme-alpine' style={{ height: 700 }}>
					<AgGridReact
						gridId='ratings'
						loadingOverlayComponent={CustomLoadingCellRenderer}
						rowData={tableData}
						columnDefs={roomsColumnDefs}
						defaultColDef={defaultColDef}
						overlayNoRowsTemplate='<span style="padding: 10px; border: 2px solid #444;">Cargando información...</span>'
						animateRows={true}
						onFilterChanged={(event: any) => {
							let filterModel = event.api.getFilterModel();
							localStorage.setItem('ratingsFilterModel', JSON.stringify(filterModel));
						}}
						onFirstDataRendered={(params: any) => {
							const filterModel = JSON.parse(localStorage.getItem('ratingsFilterModel') || '{}');
							params.api.setFilterModel(filterModel);
							// Hide columns in mobile view
							//if (window.innerWidth < 786) {

							//}
						}}
						components={{ PlayedGameImageComponent, ActionsComponent }}

					/>
				</div>
			</Box>

		</Card>
	);
} 