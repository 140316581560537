import React from 'react';
import ReactDOM from 'react-dom';
import './assets/css/App.css';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import AuthLayout from './layouts/auth';
import AdminLayout from './layouts/admin';
import { ChakraProvider } from '@chakra-ui/react';
import theme from './theme/theme';
import { AuthIsNotSignedIn, AuthIsSignedIn, AuthProvider } from 'context/auth-context';
import { StaticDataProvider } from 'context/static-data-context';

const SignInRoute: React.FunctionComponent = () => (
	<HashRouter>
		<Switch>
			<Route path={`/auth`} component={AuthLayout} />
			<Redirect to='/auth' />
		</Switch>
	</HashRouter>
)

const MainRoute: React.FunctionComponent = () => (
	<HashRouter>
		<Switch>
			<Route path={`/admin`} component={AdminLayout} />
			<Redirect from='/' to='/admin' />
		</Switch>
	</HashRouter>
)
ReactDOM.render(
	<ChakraProvider theme={theme}>
		<React.StrictMode>
			<AuthProvider>
				<AuthIsSignedIn>
					<StaticDataProvider>
						<MainRoute />
					</StaticDataProvider>
				</AuthIsSignedIn>
				<AuthIsNotSignedIn>
					<SignInRoute />
				</AuthIsNotSignedIn>
			</AuthProvider>
		</React.StrictMode>
	</ChakraProvider>,
	document.getElementById('root')
);