import { Flex, Box, Table, Checkbox, Tbody, Td, Text, Th, Thead, Tr, useColorModeValue, Image, Icon, Button } from '@chakra-ui/react';
import * as React from 'react';

// Custom components
import Card from 'components/card/Card';
import ImageComponent from 'components/ag-tables/ImageComponent';
import ActionsComponent from 'components/ag-tables/ActionsComponent';
import { AgGridReact } from 'ag-grid-react';
import { MdAddCircle } from 'react-icons/md';
import { useHistory } from 'react-router-dom';


// const columns = columnsDataCheck;
export default function GamesTable(props: { tableData: any }) {
	const { tableData } = props;

	const { ...rest } = props;
	const history = useHistory();
	const iconColor = useColorModeValue('brand.500', 'white');
	const bgHover = useColorModeValue({ bg: 'secondaryGray.400' }, { bg: 'whiteAlpha.50' });
	const bgFocus = useColorModeValue({ bg: 'secondaryGray.400' }, { bg: 'whiteAlpha.100' });
	const bgButton = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
	const textColor = useColorModeValue('secondaryGray.900', 'white');
	const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');

	//AG GRID Table definition
	const gamesColumnDefs = [
		{ field: 'image', headerName: 'Logo', sortable: false, filter: false, unSortIcon: false, floatingFilter: false, maxWidth: 100, cellRenderer: ImageComponent },
		{ field: 'name', headerName: 'Nombre' },
		{ field: 'room_name', headerName: 'Empresa' },
		{ field: 'type_name', headerName: 'Tipo' },
		{ field: 'thematic_name', headerName: 'Temática' },
		{ field: 'status_name', headerName: 'Estado' },
		{ field: 'actions', headerName: 'Acciones', sortable: false, filter: false, unSortIcon: false, floatingFilter: false, cellRenderer: ActionsComponent },
	];

	const defaultColDef = {
		flex: 1,
		sortable: true,
		filter: true,
		unSortIcon: true,
		floatingFilter: true,
		resizable: true
	};

	const goToCreateGame = () => {
		history.push(`/admin/games/create`)
	}

	return (
		<Card flexDirection='column' w='100%' px='0px' overflowX={{ sm: 'scroll', lg: 'hidden' }}>
			<Flex px='25px' mb="8px" justifyContent='space-between' align='center'>
				<Text color={textColor} fontSize='22px' mb="4px" fontWeight='700' lineHeight='100%'>
					Listado de juegos
				</Text>
				<Button
					alignItems='center'
					justifyContent='center'
					bg={bgButton}
					_hover={bgHover}
					_focus={bgFocus}
					_active={bgFocus}
					w='37px'
					h='37px'
					lineHeight='100%'
					borderRadius='10px'
					{...rest}
					onClick={goToCreateGame}>
					<Icon as={MdAddCircle} color={iconColor} w='24px' h='24px' />
				</Button>
			</Flex>
			<Box>
				<div id="games-ag-grid" className='hero ag-theme-alpine' style={{ height: 700 }}>
					<AgGridReact
						gridId='games'
						rowData={tableData}
						columnDefs={gamesColumnDefs}
						defaultColDef={defaultColDef}
						animateRows={true}
						overlayNoRowsTemplate='<span style="padding: 10px; border: 2px solid #444;">Cargando información...</span>'
						onFilterChanged={(event: any) => {
							let filterModel = event.api.getFilterModel();
							localStorage.setItem('gamesFilterModel', JSON.stringify(filterModel));
						}}
						onFirstDataRendered={(params: any) => {
							const filterModel = JSON.parse(localStorage.getItem('gamesFilterModel') || '{}');
							params.api.setFilterModel(filterModel);

							//Mobile view
							if (window.innerWidth < 786) {
								params.columnApi.setColumnVisible("type_id", false);
								params.columnApi.setColumnVisible("theme_id", false);
								params.columnApi.setColumnVisible("status_id", false);
							}
						}}
					//frameworkComponents={{LinkComponent}}
					/>
				</div>
			</Box>
		</Card>
	);
} 