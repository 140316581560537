import React, { useState, useEffect, useContext } from 'react';

// Chakra imports
import { Box } from '@chakra-ui/react';

import RatingsTable from './components/RatingsTable';

import { AuthContext } from 'context/auth-context';
import { StaticDataContext } from 'context/static-data-context';
import { iRating } from 'types/iRating';
import { getFullList } from 'services/playedGamesService';

export default function Ratings() {
	const { currentUser } = useContext(AuthContext);
	const { staticRooms, staticGames, staticUsers, staticTeams } = useContext(StaticDataContext);
	const ratingsList: iRating[] = [];
	//State
	const [ratings, setRatings]: [iRating[], (ratingsList: iRating[]) => void] = useState(ratingsList);

	const tableFields = "id,image,room_id,game_id,team_id,team_played,user_id,played_at,is_verified,immersion,game_master,history,puzzles,fear,difficulty,average";

	useEffect(() => {
		getFullList(currentUser, tableFields).then((ratingsList: iRating[]) => {

			ratingsList.map(rating => {
				if (rating.room_id && rating.room_id !== '-') {
					const id = rating.room_id || "";
					const pos = staticRooms.map(e => e.id).indexOf(id);
					rating.room_name = staticRooms[pos].name;
				} else {
					rating.room_name = "Sin Empresa";
				}

				if (rating.game_id && rating.game_id !== '') {
					const id = rating.game_id || "";
					const pos = staticGames.map(e => e.id).indexOf(id);
					const gameName = (pos >= 0 ? staticGames[pos].name : "Juego no encontrado");
					rating.game_name = gameName;
				} else {
					rating.game_name = "Sin Juego";
				}

				if (rating.user_id && rating.user_id !== '') {
					const id = rating.user_id || "";
					const pos = staticUsers.map(e => e.id).indexOf(id);
					const userName = (pos >= 0 ? staticUsers[pos].name : "Usuario no encontrado");
					rating.user_nickname = userName;
				} else {
					rating.user_nickname = "Sin Jugador";
				}

				if (rating.team_id) {
					const id = rating.team_id || "";
					const pos = staticTeams.map(e => e.id).indexOf(id);
					const userName = (pos >= 0 ? staticTeams[pos].name : "Equipo no encontrado");
					rating.team_name = userName;
				} else {
					rating.team_name = "";
				}

			});
			setRatings(ratingsList);
		});
	}, []);

	return (
		<Box pt={{ base: '180px', md: '80px', xl: '80px' }}>
			{/* Main Fields */}
			<RatingsTable tableData={ratings} />
		</Box>
	);
}
