import { updateSingleItem } from "services/claimsService";
import { iRoomClaimed } from "types/iRoomClaimed";

function checkedHandler(props: any) {

  const checkValue = props.node.data.is_verified;
  const colId = props?.column.colId;
  const isActiveNewValue = !checkValue
  props.node.setDataValue(colId, isActiveNewValue);
  const gridId = props.api.getGridId();
  switch (gridId) {
    case 'claims':
      const claim: iRoomClaimed = {
        id: props.node.data.id,
        is_active: isActiveNewValue,
      }
      updateSingleItem(claim);
      break;
  }
}

function CheckboxComponent(props: any) {

  return (
    <input
      type="checkbox"
      onClick={() => checkedHandler(props)}
      //@ts-ignore
      checked={props.value}
    />
  );
}

export default CheckboxComponent;