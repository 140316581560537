import { User } from "firebase/auth";
import { useHistory } from "react-router-dom";
import { SignOutUser, userStateListener } from "../libs/firebase/firebase";
import { createContext, useState, useEffect, ReactNode, SetStateAction, useContext } from "react";

interface Props {
    children?: ReactNode
}

export const AuthContext = createContext({
    // "User" comes from firebase auth-public.d.ts
    currentUser: {} as User | null,
    setCurrentUser: (_user: User) => { },
    signOut: () => { }
});

export const AuthIsSignedIn = ({ children }: Props) => {
    const { currentUser } = useContext(AuthContext)

    localStorage.setItem("user", JSON.stringify(currentUser));
    return <>{currentUser !== null ? children : null}</>
}

export const AuthIsNotSignedIn = ({ children }: Props) => {
    const { currentUser } = useContext(AuthContext)

    return <>{currentUser === null ? children : null}</>
}

export const AuthProvider = ({ children }: Props) => {
    const [currentUser, setCurrentUser] = useState<User | null>(null)
    
    const history = useHistory()

    useEffect(() => {
        const unsubscribe = userStateListener((user: SetStateAction<User>) => {
            if (user) {
                localStorage.setItem("user", JSON.stringify(currentUser));
                setCurrentUser(user)
            }
        });
        return unsubscribe
    }, [setCurrentUser]);

    // As soon as setting the current user to null, 
    // the user will be redirected to the home page. 
    const signOut = () => {
        SignOutUser()
        localStorage.clear();
        setCurrentUser(null)
    }

    const value = {
        currentUser,
        setCurrentUser,
        signOut
    }

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}