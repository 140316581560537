
// Chakra imports
import { Box, Flex, Icon, SimpleGrid, useColorModeValue } from '@chakra-ui/react';
// Assets
import banner from 'assets/img/backgrounds/banner.png';
import { AuthContext } from 'context/auth-context';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getSingleItem } from 'services/gamesService';
import { getSingleItem as getMetrics } from 'services/metricsService';

// Custom components
import Info from 'views/admin/games/detail/components/Info';
import Profile from 'views/admin/games/detail/components/Profile';
import GameData from 'views/admin/games/detail/components/GameData';
import MiniStatistics from 'components/card/MiniStatistics';
import IconBox from 'components/icons/IconBox';


// Assets
import { IoAnalyticsOutline } from 'react-icons/io5';
import { GiPlayerTime, GiPuzzle } from 'react-icons/gi';
import { iMetric } from 'types/iMetric';
import { iGame } from 'types/iGame';

export default function GameDetails() {

	const { currentUser } = useContext(AuthContext);
	const params: any = useParams();

	// Chakra Color Mode
	const brandColor = useColorModeValue('brand.500', 'white');
	const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');

	const gameDetail: iGame = {
	};
	//State
	const [game, setGame]: [iGame, (gameDetail: iGame) => void] = useState(gameDetail);
	const [gameMetrics, setGameMetrics]: [iMetric, (metric: iMetric) => void] = useState({});

	useEffect(() => {

		getSingleItem(currentUser, params['game_id']).then((gameDetail: iGame) => {
			console.log("Loaded: ");
			console.log({ gameDetail });
			setGame(gameDetail);
		});

		getMetrics(currentUser, "game:" + params['game_id']).then((metrics: iMetric) => {
			setGameMetrics(metrics);
		})

	}, []);

	return (
		<Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
			<SimpleGrid columns={{ base: 1, md: 2, lg: 3, '2xl': 6 }} gap='20px' mb='20px'>
				<MiniStatistics
					startContent={
						<IconBox
							w='56px'
							h='56px'
							bg={boxBg}
							icon={<Icon w='32px' h='32px' as={GiPuzzle} color={brandColor} />}
						/>
					}
					name='Juegos'
					value={gameMetrics?.games || 0}
				/>
				<MiniStatistics
					startContent={
						<IconBox
							w='56px'
							h='56px'
							bg={boxBg}
							icon={<Icon w='32px' h='32px' as={GiPlayerTime} color={brandColor} />}
						/>
					}
					name='Partidas'
					value={gameMetrics?.played_games || 0}
				/>
				<MiniStatistics
					startContent={
						<IconBox
							w='56px'
							h='56px'
							bg='linear-gradient(90deg, #F1505E 0%, #f6969e 100%)'
							icon={<Icon w='28px' h='28px' as={IoAnalyticsOutline} color='white' />}
						/>
					}
					name='Media'
					value={gameMetrics?.average || 0}
				/>
			</SimpleGrid>
			<SimpleGrid mb='20px' columns={{ sm: 1, lg: 2 }} spacing={{ base: '20px', xl: '20px' }}>
				{/* Column Left */}
				<Flex direction='column'>
					<Profile game={game} banner={banner} />
					<GameData game={game} />
				</Flex>
				{/* Column Right */}
				<Flex direction='column'>
					<Info game={game} />
				</Flex>
			</SimpleGrid>
		</Box>
	);
}
