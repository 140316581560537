import { User } from "firebase/auth";

async function getFullList(currentUser: User, fields?: string) {

    const currentToken = await currentUser.getIdToken(true)

    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': process.env.REACT_APP_FOBOS_API_KEY,
            'Authorization': 'Bearer ' + currentToken
        }
    }

    const tableFields = (fields ? "fields=" + fields : "");
    let result = await fetch(process.env.REACT_APP_FOBOS_API_HOST + '/users', options)
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            return data;
        });

    while (result.lastKey && result.lastKey !== "false") {
        const iterativeResult = await fetch(process.env.REACT_APP_FOBOS_API_HOST + '/users?' + tableFields + "&lastKey=" + result.lastKey, options)
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                return data;
            });
        result.data = [...result.data, ...iterativeResult.data];
        result.lastKey = iterativeResult.lastKey.id;
    }

    return result.data;
}

export { getFullList };

async function getUser(currentUser: User, user_id: string) {

    const currentToken = await currentUser.getIdToken(true);

    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': process.env.REACT_APP_FOBOS_API_KEY,
            'Authorization': 'Bearer ' + currentToken
        }
    }

    return fetch(process.env.REACT_APP_FOBOS_API_HOST + '/users/' + user_id, options)
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            return data;
        });

}
export { getUser };

async function getUserTeams(currentUser: User, user_id: string) {

    const currentToken = await currentUser.getIdToken(true);

    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': process.env.REACT_APP_FOBOS_API_KEY,
            'Authorization': 'Bearer ' + currentToken
        }
    }

    return fetch(process.env.REACT_APP_FOBOS_API_HOST + '/users/' + user_id + '/teams', options)
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            return data;
        });

}
export { getUserTeams };