// Chakra imports
import { Button, Flex, FormControl, Select, SimpleGrid, Text, useColorModeValue } from '@chakra-ui/react';
import Card from 'components/card/Card';
import InputField from 'components/fields/InputField';
import TextField from 'components/fields/TextField';
import { StaticDataContext } from 'context/static-data-context';
import { sorters } from 'libs/arrayHelper';
import { useContext, useEffect, useState } from 'react';
import { updateSingleItem } from 'services/roomsService';
import { iRoom } from 'types/iRoom';
import { iTaxonomyTerm } from 'types/iTaxonomyTerm';

export default function Info(props: { room: iRoom }) {
	const { staticTaxonomyCountries, staticTaxonomyProvinces, staticTaxonomyCities } = useContext(StaticDataContext);

	// Chakra Color Mode
	const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
	const textColorSecondary = 'secondaryGray.600';
	const { room } = props;
	const [roomData, setRoomData] = useState(room);

	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [phone, setPhone] = useState("");
	const [country, setCountry] = useState("");
	const [province, setProvince] = useState("");
	const [city, setCity] = useState("");
	const [address, setAddress] = useState("");
	const [description, setDescription] = useState("");
	const [latitude, setLatitude] = useState("");
	const [longitude, setLongitude] = useState("");

	useEffect(() => {
		setRoomData(room);
		setName(room.name);
		setEmail(room.email);
		setPhone(room.phone);
		setCountry(room.country);
		setProvince(room.province);
		setCity(room.city);
		setAddress(room.address);
		setDescription(room.description);
		setLatitude(room.latitude);
		setLongitude(room.longitude);
		console.log("room data loaded!")
	});

	const saveInfo = async () => {
		const roomInfo: iRoom = {
			id: room.id,
			name,
			email,
			phone,
			country,
			province,
			city,
			address,
			description,
			latitude,
			longitude
		}
		const savedInfos = await updateSingleItem(roomInfo);
		if (savedInfos === 200) {
			alert("Informaciones actualizadas correctamente")
		} else {
			alert("Ha ocurrido un problema, por favor, vuelve a intentar guardar");
		}
	}

	return (
		<FormControl>
			<Card>
				<Flex direction='column' mb='10px' ms='10px'>
					<Text fontSize='xl' color={textColorPrimary} fontWeight='bold'>
						Datos de la empresa
					</Text>
					<Text fontSize='md' color={textColorSecondary}>
						Define los datos de la empresa
					</Text>
				</Flex>
				<SimpleGrid columns={{ sm: 1, md: 2 }} spacing={{ base: '20px', xl: '20px' }}>
					<InputField mb='25px' me='30px' id='name' label='Nombre de la empresa' placeholder='Fobos Escape' defaultValue={name} />
					<InputField mb='25px' id='email' label='Email' placeholder='contacto@fobosescape.com' defaultValue={email} />
					<InputField mb='25px' me='30px' id='phone' label='Teléfono' placeholder='+34-666-666-666' defaultValue={phone} />
					<Select
						id='country'
						w='unset'
						display='flex'
						textColor={textColorPrimary}
						color={textColorPrimary}
						alignItems='center'
						placeholder='País'
						marginTop={30}
						value={country} onChange={(e: any) => setCountry(e.target.value)}
					>
						{staticTaxonomyCountries.sort(sorters.byName).map((element: iTaxonomyTerm) => (
							<option value={element.id}>{element.name}</option>
						))}
					</Select>
					<Select
						id='province'
						w='unset'
						display='flex'
						textColor={textColorPrimary}
						color={textColorPrimary}
						alignItems='center'
						placeholder='Provincia'
						marginBottom={8}
						value={province} onChange={(e: any) => setProvince(e.target.value)}
					>
						{staticTaxonomyProvinces.sort(sorters.byName).map((element: iTaxonomyTerm) => (
							<option value={element.id}>{element.name}</option>
						))}
					</Select>
					<Select
						id='city'
						w='unset'
						display='flex'
						textColor={textColorPrimary}
						color={textColorPrimary}
						alignItems='center'
						placeholder='Ciudad'
						value={city} onChange={(e: any) => setCity(e.target.value)}
					>
						{staticTaxonomyCities.sort(sorters.byName).map((element: iTaxonomyTerm) => (
							<option value={element.id}>{element.name}</option>
						))}
					</Select>
				</SimpleGrid>
				<InputField id='address' label='Dirección' placeholder='C/ de la Piruleta' defaultValue={address} />
				<TextField
					id='description'
					label='Descripción de la empresa'
					h='100px'
					placeholder='Descripción de la empresa que va a aparecer en la app'
					defaultValue={description}
				/>
				<Button variant='darkBrand' minW='183px' fontSize='sm' fontWeight='500' ms='auto' onClick={saveInfo}>
					Guardar
				</Button>
			</Card>
		</FormControl>
	);
}
