// Chakra imports
import { Button, Flex, FormControl, Select, SimpleGrid, Text, useColorModeValue } from '@chakra-ui/react';
import Card from 'components/card/Card';
import InputField from 'components/fields/InputField';
import TextField from 'components/fields/TextField';
import { AuthContext } from 'context/auth-context';
import { StaticDataContext } from 'context/static-data-context';
import { sorters } from 'libs/arrayHelper';
import { isInt } from 'libs/typesHelper';
import { useContext, useEffect, useState } from 'react';
import { updateSingleItem } from 'services/gamesService';
import { iGame } from 'types/iGame';

export default function Info(props: { game: iGame }) {
	const { currentUser } = useContext(AuthContext);

	// Chakra Color Mode
	const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
	const textColorSecondary = 'secondaryGray.600';
	const { game } = props;

	const [gameData, setGameData]: [iGame, (gameDetail: iGame) => void] = useState();

	useEffect(() => {
		setGameData(game);
	});

	const changeHandler = (event: any) => {
		let tmpGame = gameData;

		if (isInt(event.target.value)) {
			//@ts-ignore
			tmpGame[event.target.id] = parseInt(event.target.value);
		} else {
			//@ts-ignore
			tmpGame[event.target.id] = event.target.value
		}

		setGameData(tmpGame);
	}

	const saveInfo = async () => {
		const gameInfo: iGame = {
			id: game.id,
			name: gameData.name,
			description: gameData.description,
			min_players: gameData.min_players,
			max_players: gameData.max_players,
			min_price: gameData.min_price,
			max_price: gameData.max_price,
			time: gameData.time,
			room_id: game.room_id
		}
		console.log(gameInfo);
		const savedInfos = await updateSingleItem(currentUser, gameInfo);
		if (savedInfos === 200) {
			alert("Informaciones actualizadas correctamente")
		} else {
			alert("Ha ocurrido un problema, por favor, vuelve a intentar guardar");
		}
	}

	return (
		<FormControl>
			<Card>
				<Flex direction='column' mb='10px' ms='10px'>
					<Text fontSize='xl' color={textColorPrimary} fontWeight='bold'>
						Información del juego
					</Text>
					<Text fontSize='md' color={textColorSecondary}>
						Revisa/actualiza la información del juego
					</Text>
				</Flex>
				<SimpleGrid columns={{ sm: 1, md: 1 }} spacing={{ base: '20px', xl: '20px' }}>
					<InputField mb='25px' me='30px' id='name' label='Nombre del juego' placeholder='Fani´s adventure' defaultValue={gameData?.name || ""} onChange={(e: any) => { changeHandler(e); }} />
				</SimpleGrid>
				<TextField
					id='description'
					label='Descripción del juego'
					h='100px'
					placeholder='Descripción del juego que va a aparecer en la app'
					defaultValue={gameData?.description || ""}
					minH={60}
					onChange={(e: any) => { changeHandler(e); }}
				/>
				<SimpleGrid columns={{ sm: 2 }} spacing={{ base: '20px', xl: '20px' }}>
					<InputField mb='25px' me='30px' id='min_players' label='Min. Jugadores' placeholder='1' defaultValue={gameData?.min_players} onChange={(e: any) => { changeHandler(e); }} />
					<InputField mb='25px' me='30px' id='max_players' label='Max. Jugadores' placeholder='1' defaultValue={gameData?.max_players} onChange={(e: any) => { changeHandler(e); }} />
					<InputField mb='25px' me='30px' id='min_price' label='Precio Min.' placeholder='1' defaultValue={gameData?.min_price} onChange={(e: any) => { changeHandler(e); }} />
					<InputField mb='25px' me='30px' id='max_price' label='Precio Max.' placeholder='1' defaultValue={gameData?.max_price} onChange={(e: any) => { changeHandler(e); }} />
					<InputField mb='25px' me='30px' id='time' label='Tiempo' placeholder='1' defaultValue={gameData?.time} onChange={(e: any) => { changeHandler(e); }} />

				</SimpleGrid>
				<Button variant='darkBrand' minW='183px' fontSize='sm' fontWeight='500' ms='auto' onClick={saveInfo}>
					Guardar
				</Button>
			</Card>
		</FormControl>
	);
}
