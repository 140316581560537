import { useContext, useState } from "react";
import { Icon } from "@chakra-ui/icons";
import { useColorModeValue, Link, Button, useDisclosure, Input } from "@chakra-ui/react";
import { Link as ReachLink } from "react-router-dom";
import { TfiWrite } from "react-icons/tfi";
import { TiDelete } from "react-icons/ti";
import { AuthContext } from "context/auth-context";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react'

import { deleteSingleItem as deleteTaxonomy } from "services/taxonomiesService";

function ActionsComponent(props: any) {
  const { variant, background, children, placeholder, borderRadius, ...rest } = props;
  const { currentUser } = useContext(AuthContext);

  const inputBg = useColorModeValue('secondaryGray.300', 'fobos.darkGrey');
  const inputText = useColorModeValue('gray.700', 'gray.100');

  const { isOpen, onOpen, onClose } = useDisclosure()
  let menuColor = useColorModeValue('gray.400', 'black');

  const [deleteIsDisabled, setDeleteIsDisabled] = useState(true)
  const confirmDeletion = (event: any) => {
    if (event.target.value === 'confirmar')
      setDeleteIsDisabled(false)
    else
      setDeleteIsDisabled(true)
  }

  const performDeletion = (gridId: string, elementId: string) => {
    if (!deleteIsDisabled) {
      switch (gridId) {
        case 'taxonomies':
          deleteTaxonomy(currentUser, elementId).then((deletion: any) => {
            props?.node.setData({});
            onClose();
          });
          break;
      }
    }
  };


  return (
    <>
      <Link as={ReachLink} to={`${props.api.getGridId()}/${props.node.data.id}`} title="Ver/editar">
        <Icon
          as={TfiWrite}
          color={`blue`}
          my='auto'
          w='20px'
          h='20px'
          me='10px'
          _hover={{ cursor: 'pointer' }}
        />
      </Link>
      <Link onClick={onOpen} title="Borrar">
        <Icon
          as={TiDelete}
          color={`red`}
          my='auto'
          w='20px'
          h='20px'
          me='10px'
          _hover={{ cursor: 'pointer' }}
        />
      </Link>

      <Modal isOpen={isOpen} onClose={onClose} size='xl'>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>¿Deseas borrar este elemento?</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Estas a punto de borrar este elemento definitivamente del sistema. Por favor escribe <i>confirmar</i> para poder proceder con el borrado
            <Input placeholder='Escribe "confirmar"' bg={background ? background : inputBg} color={inputText} onChange={confirmDeletion} />
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='green' mr={3} onClick={onClose}>
              No
            </Button>
            <Button colorScheme='red' isDisabled={deleteIsDisabled} onClick={() => performDeletion(props.api.getGridId(), props.node.data.id)}>SI (NO IMPLEMENTADO AÚN)</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default ActionsComponent;