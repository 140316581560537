// Chakra imports
import { Box, Icon, SimpleGrid, useColorModeValue } from '@chakra-ui/react';

// Custom components
import MiniStatistics from 'components/card/MiniStatistics';
import IconBox from 'components/icons/IconBox';
import { MdOutlineRoomPreferences, MdHomeWork } from 'react-icons/md';
import { GiPuzzle } from "react-icons/gi";
import { IoAnalyticsOutline } from 'react-icons/io5';
import { HiOutlineUsers } from 'react-icons/hi';
import { useContext, useEffect, useState } from 'react';
import { getSingleItem } from 'services/metricsService';
import { AuthContext } from 'context/auth-context';
import { iMetric } from 'types/iMetric';

export default function UserReports() {
	const { currentUser } = useContext(AuthContext);

	// Chakra Color Mode
	const brandColor = useColorModeValue('brand.500', 'white');
	const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');

	const [metricRooms, setMetricRooms]: [iMetric, (metric: iMetric) => void] = useState();
	const [metricGames, setMetricGames]: [iMetric, (metric: iMetric) => void] = useState();
	const [metricClaims, setMetricClaims]: [iMetric, (metric: iMetric) => void] = useState();
	const [metricPlayedGames, setMetricPlayedGames]: [iMetric, (metric: iMetric) => void] = useState();
	const [metricUsers, setMetricUsers]: [iMetric, (metric: iMetric) => void] = useState();

	useEffect(() => {

		getSingleItem(currentUser, "rooms").then((metrics: iMetric) => {
			setMetricRooms(metrics);
		});

		getSingleItem(currentUser, "games").then((metrics: iMetric) => {
			setMetricGames(metrics);
		});

		getSingleItem(currentUser, "users").then((metrics: iMetric) => {
			setMetricUsers(metrics);
		});

		getSingleItem(currentUser, "played-games").then((metrics: iMetric) => {
			setMetricPlayedGames(metrics);
		});

		getSingleItem(currentUser, "claims").then((metrics: iMetric) => {
			setMetricClaims(metrics);
		});
	}, []);
	return (
		<Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
			<SimpleGrid columns={{ base: 1, md: 2, lg: 4, '2xl': 6 }} gap='20px' mb='20px'>
				<MiniStatistics
					startContent={
						<IconBox
							w='56px'
							h='56px'
							bg={boxBg}
							icon={<Icon w='32px' h='32px' as={MdHomeWork} color={brandColor} />}
						/>
					}
					name='Empresas'
					value={metricRooms?.count || 0}
				/>
				<MiniStatistics
					startContent={
						<IconBox
							w='56px'
							h='56px'
							bg={boxBg}
							icon={<Icon w='32px' h='32px' as={GiPuzzle} color={brandColor} />}
						/>
					}
					name='Juegos'
					value={metricGames?.count || 0}
				/>
				<MiniStatistics
					startContent={
						<IconBox
							w='56px'
							h='56px'
							bg={boxBg}
							icon={<Icon w='28px' h='28px' as={MdOutlineRoomPreferences} color='white' />}
						/>
					}
					name='Reclamaciones'
					value={metricClaims?.count || 0}
				/>
				<MiniStatistics
					startContent={
						<IconBox
							w='56px'
							h='56px'
							bg={boxBg}
							icon={<Icon w='32px' h='32px' as={IoAnalyticsOutline} color={brandColor} />}
						/>
					}
					name='Partidas'
					value={metricPlayedGames?.count || 0}
				/>
				<MiniStatistics
					startContent={
						<IconBox
							w='56px'
							h='56px'
							bg={boxBg}
							icon={<Icon w='32px' h='32px' as={HiOutlineUsers} color={brandColor} />}
						/>
					}
					name='Usuarios'
					value={metricUsers?.count || 0}
				/>
			</SimpleGrid>
		</Box>
	);
}
