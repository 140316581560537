import React, { useContext, useEffect, useState } from 'react';

// Chakra imports
import { Box, useColorModeValue } from '@chakra-ui/react';

import GamesTable from './components/GamesTable';

import { getFullList } from 'services/gamesService';
import { AuthContext } from 'context/auth-context';
import { iGame } from 'types/iGame';
import { StaticDataContext } from 'context/static-data-context';

export default function Games() {

	const { currentUser } = useContext(AuthContext);
	const { staticRooms, staticTaxonomyGameTypes, staticTaxonomyThematic, staticTaxonomyRoomStatuses } = useContext(StaticDataContext);

	const gamesList: iGame[] = [];
	//State
	const [games, setGames]: [iGame[], (roomList: iGame[]) => void] = useState(gamesList);
	const tableFields = "id,image,name,room_id,type_id,thematic_id,status_id";
	useEffect(() => {
		getFullList(currentUser, tableFields).then((gamesList: iGame[]) => {
			gamesList.map(game => {
				if (game.room_id && game.room_id !== '-') {
					const roomId = game.room_id || "";
					const roomPos = staticRooms.map(e => e.id).indexOf(roomId);
					game.room_name = staticRooms[roomPos].name;
				} else {
					game.room_name = "Sin Empresa";
				}

				if (game.type_id && game.type_id !== "-") {
					const typeId = game.type_id || "";
					const typePos = staticTaxonomyGameTypes.map(e => e.id).indexOf(typeId);
					game.type_name = staticTaxonomyGameTypes[typePos].name;
				} else {
					game.type_name = "Sin tipo";
				}

				if (game.thematic_id && game.thematic_id !== "-") {
					const thematicId = game.thematic_id || "";
					const thematicPos = staticTaxonomyThematic.map(e => e.id).indexOf(thematicId);
					game.thematic_name = staticTaxonomyThematic[thematicPos].name;
				} else {
					game.thematic_name = "Sin temática";
				}

				if (game.status_id && game.status_id !== "-") {
					const statusId = game.status_id || "";
					const statusPos = staticTaxonomyRoomStatuses.map(e => e.id).indexOf(statusId);
					game.status_name = staticTaxonomyRoomStatuses[statusPos].name;
				} else {
					game.status_name = "Sin Estado";
				}
			})
			setGames(gamesList)
		});
	}, []);

	return (
		<Box pt={{ base: '180px', md: '80px', xl: '80px' }}>
			{/* Main Fields */}
			<GamesTable tableData={games} />
		</Box>
	);
}